import { ChipStyled, StackStyled } from './AutocompleteChip.styles';
import type { AutocompleteChipProps } from './autocompleteChip.type';

export const AutocompleteChip = ({
    value,
    getTagProps,
    getOptionLabel = (option) => option.label,
}: AutocompleteChipProps) => (
    <StackStyled direction="row">
        {value.map((option, index) => (
            // Key provided by getTagProps
            // eslint-disable-next-line react/jsx-key
            <ChipStyled
                {...getTagProps({ index })}
                size="small"
                label={getOptionLabel(option)}
            />
        ))}
    </StackStyled>
);
