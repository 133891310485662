import React from 'react';
import { useTranslation } from 'react-i18next';
import { CharacterCounterStyled } from './characterCounter.styles';
import { CharacterCounterProps } from './characterCounter.type';

export const CharacterCounter = ({
    currentCharacters,
    minCharacters = 55,
    maxCharacters = 70,
}: CharacterCounterProps) => {
    const { t } = useTranslation();

    return (
        <CharacterCounterStyled>
            {t('common:labels.characters', {
                count: currentCharacters,
                countMin: minCharacters,
                countMax: maxCharacters,
            })}
        </CharacterCounterStyled>
    );
};

export default CharacterCounter;
