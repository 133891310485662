import {
    mapChannelPatternFromAPI,
    mapChannelPatternListFromAPI,
    mapPatternUpdateToAPI,
} from 'services/salesChannels/mappers/channelPatterns';
import { mapColorsFromAPI } from 'services/salesChannels/mappers/colors';
import {
    ApplicationJsonHeaders,
    ContentTypeJsonHeader,
    HttpPatchHeaders,
} from 'services/constants';
import {
    mapChannelPatternTitleEditToAPI,
    mapChannelPatternTitlesFromAPI,
    mapChannelPatternTitleToAPI,
} from 'services/salesChannels/mappers/titles';
import uniq from 'lodash/uniq';
import { mapChannelCategoriesFromAPI } from 'services/salesChannels/mappers/channelCategories';
import { mapTagsFromAPI } from 'services/salesChannels/mappers/channelTags';
import type { SalesChannelsService } from '../SalesChannelsService';
import type { ValueOf } from '../../../utils/types/valueOf';
import { IServiceMethodParams } from '../../../types/services.type';
import { parsePostFilters } from '../../../utils/filters';
import { mapTranslationPatternFromApi } from '../mappers/channelPatterns';

const getUpdateString = (key: string) => {
    switch (key) {
        case 'tags':
            return 'translations:alerts.tagsEdited';
        case 'categories':
            return 'translations:alerts.categoriesEdited';
        default:
            return '';
    }
};

export class ChannelPatterns {
    private readonly UPLOAD_URLS: Record<
        'categories' | 'tags' | 'titles',
        ValueOf<
            Pick<
                SalesChannelsService['URLS'],
                | 'POST_IMPORT_CHANNEL_PATTERN_CATEGORIES'
                | 'POST_IMPORT_CHANNEL_PATTERN_TAGS'
                | 'POST_IMPORT_CHANNEL_PATTERN_TITLES'
            >
        >
    >;

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.UPLOAD_URLS = {
            categories:
                salesChannelsService.URLS
                    .POST_IMPORT_CHANNEL_PATTERN_CATEGORIES,
            tags: salesChannelsService.URLS.POST_IMPORT_CHANNEL_PATTERN_TAGS,
            titles: salesChannelsService.URLS
                .POST_IMPORT_CHANNEL_PATTERN_TITLES,
        };

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.getColorsByChannelAndPattern =
            this.getColorsByChannelAndPattern.bind(this);
        this.getTranslationsByChannelAndPattern =
            this.getTranslationsByChannelAndPattern.bind(this);
        this.createTitle = this.createTitle.bind(this);
        this.editTitle = this.editTitle.bind(this);
        this.updatePattern = this.updatePattern.bind(this);
        this.getCategoriesByChannelAndPattern =
            this.getCategoriesByChannelAndPattern.bind(this);
        this.getTagsByChannelAndPattern =
            this.getTagsByChannelAndPattern.bind(this);

        this.importCategories = this.importCategories.bind(this);
        this.importTags = this.importTags.bind(this);
        this.importTitles = this.importTitles.bind(this);
        this.postChannelPatterns = this.postChannelPatterns.bind(this);
    }

    private static channelIdField = '{channelId}';
    private static patternCodeField = '{patternCode}';

    private fetchChannelPattern(channelId, patternCode, errorConfig = {}) {
        if (!channelId) {
            throw new Error(
                'Cannot fetch pattern details for unknown channel!',
            );
        }

        const { ajax, URLS, HEADERS } = this.salesChannelsService;

        const url = URLS.GET_CHANNEL_PATTERN_V2.replace(
            ChannelPatterns.channelIdField,
            channelId,
        ).replace(ChannelPatterns.patternCodeField, patternCode);
        const config = {
            headers: HEADERS.basic,
        };

        return ajax.get({ url, config, errorConfig });
    }

    private static mapFiltersToApi(filters) {
        const {
            patternCode,
            incompleteCategories,
            incompleteColors,
            incompleteTags,
            incompleteTitles,
        } = filters;

        let parsedFilters: {
            'pattern.code'?: string | undefined;
            complete_categories?: boolean | undefined;
            complete_colors?: boolean | undefined;
            complete_tags?: boolean | undefined;
            complete_titles?: boolean | undefined;
        } = {};

        if (patternCode) {
            parsedFilters['pattern.code'] = patternCode;
        }
        if (incompleteCategories) {
            parsedFilters.complete_categories = false;
        }
        if (incompleteColors) {
            parsedFilters.complete_colors = false;
        }
        if (incompleteTags) {
            parsedFilters.complete_tags = false;
        }
        if (incompleteTitles) {
            parsedFilters.complete_titles = false;
        }

        return parsedFilters;
    }

    private static mapSortToApi(sort) {
        const { created_at } = sort;

        if (created_at) {
            return { ['pattern.created_at']: created_at };
        }

        return sort;
    }

    async getById(channelId: string, patternCode: string) {
        const { data } = await this.fetchChannelPattern(channelId, patternCode);

        return mapChannelPatternFromAPI(data);
    }

    async getColorsByChannelAndPattern(channelId: string, patternCode: string) {
        const { errorHandlers } = this.salesChannelsService;

        const { data } = await this.fetchChannelPattern(
            channelId,
            patternCode,
            {
                addGenericAlert: false,
                throwError: errorHandlers.get,
            },
        );

        return {
            items: (data?.additional_colors || data?.colors || []).map(
                mapColorsFromAPI,
            ),
            mainColor: data?.main_color
                ? mapColorsFromAPI(data.main_color)
                : null,
            totalItems:
                data?.additional_colors?.length || data?.colors?.length || 0,
            id: (data?.additional_colors || data?.colors)?.[0]?.id,
        };
    }

    async getCategoriesByChannelAndPattern(
        channelId: string,
        patternCode: string,
    ) {
        const { errorHandlers } = this.salesChannelsService;

        const { data } = await this.fetchChannelPattern(
            channelId,
            patternCode,
            {
                addGenericAlert: false,
                throwError: errorHandlers.get,
            },
        );

        return {
            items: (data?.categories || []).map(mapChannelCategoriesFromAPI),
            id: data?.categories?.[0]?.id,
        };
    }

    async getTagsByChannelAndPattern(channelId: string, patternCode: string) {
        const { errorHandlers } = this.salesChannelsService;

        const { data } = await this.fetchChannelPattern(
            channelId,
            patternCode,
            {
                addGenericAlert: false,
                throwError: errorHandlers.get,
            },
        );

        return {
            items: (data?.tags || []).map(mapTagsFromAPI),
            totalItems: data?.tags?.length || 0,
            id: data?.tags?.[0]?.id,
        };
    }

    async getTranslationsByChannelAndPattern(
        channelId: string,
        patternCode: string,
    ) {
        const { errorHandlers } = this.salesChannelsService;

        const { data } = await this.fetchChannelPattern(
            channelId,
            patternCode,
            {
                addGenericAlert: false,
                throwError: errorHandlers.get,
            },
        );

        return {
            totalItems: data?.titles?.length || 0,
            items: (data?.titles || []).map(mapChannelPatternTitlesFromAPI),
        };
    }

    async createTitle(channelId: string, patternCode: string, data) {
        const { ajax, URLS, alert, translator, errorHandlers } =
            this.salesChannelsService;
        const url = URLS.POST_CHANNEL_PATTERN_TITLE.replace(
            ChannelPatterns.channelIdField,
            channelId,
        ).replace(ChannelPatterns.patternCodeField, patternCode);
        const config = {
            headers: ContentTypeJsonHeader,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.post,
        };

        await ajax.post({
            url,
            config,
            errorConfig,
            data: mapChannelPatternTitleToAPI(data),
        });

        const { items } = await this.getTranslationsByChannelAndPattern(
            channelId,
            patternCode,
        );

        const newItem = items.find(
            (item) =>
                item.language === data?.language && item.title === data?.title,
        );

        alert.addSuccess({
            message: translator.t('translations:alerts.translationAdded'),
        });

        return newItem;
    }

    async editTitle(channelId, patternCode, data) {
        const { ajax, URLS, alert, translator, errorHandlers } =
            this.salesChannelsService;
        const id = data.patternTranslationId;
        const formattedData = mapChannelPatternTitleEditToAPI(data);
        const url = URLS.PUT_CHANNEL_PATTERN_TITLE.replace(
            ChannelPatterns.channelIdField,
            channelId,
        )
            .replace(ChannelPatterns.patternCodeField, patternCode)
            .replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.put,
        };

        await ajax.put({ url, config, data: formattedData, errorConfig });

        const { items } = await this.getTranslationsByChannelAndPattern(
            channelId,
            patternCode,
        );
        const updatedItem = items.find((item) => item.id === id);

        alert.addSuccess({
            message: translator.t('translations:alerts.translationEdited'),
        });

        return updatedItem;
    }

    async getList(channelId: string, params: Record<string, unknown> = {}) {
        const { ajax, URLS, DEFAULTS, paramsSerializer, errorHandlers } =
            this.salesChannelsService;
        const {
            page = 1,
            per_page: itemsPerPage = DEFAULTS.PATTERNS_PER_PAGE,
            sort = {},
            filters = {},
        } = params;

        const parsedParams = {
            ...ChannelPatterns.mapFiltersToApi(filters),
            page,
            itemsPerPage,
            order: ChannelPatterns.mapSortToApi(sort),
        };

        const url = URLS.GET_CHANNEL_PATTERNS.replace('{id}', channelId);
        const config = {
            params: parsedParams,
            paramsSerializer,
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            onError: errorHandlers.get,
        };

        const { data } = await ajax.get({ url, config, errorConfig });

        return {
            items: mapChannelPatternListFromAPI(data?.results || []),
            totalItems: data?.total || 0,
        };
    }

    private updatePatternAlert(data: {
        categories: object[] | undefined;
        tags: object[] | undefined;
    }) {
        const { alert, translator } = this.salesChannelsService;

        const mappedUpdate = mapPatternUpdateToAPI(data);

        const alertKeys = uniq(
            Object.keys(mappedUpdate)
                .map((key) => getUpdateString(key))
                .filter((updateString) => !!updateString),
        );

        alertKeys.forEach((key) => {
            alert.addSuccess({ message: translator.t(key) });
        });
    }

    async updatePattern(
        channelId: string,
        patternCode: string,
        data: {
            categories: object[] | undefined;
            tags: object[] | undefined;
        },
    ) {
        const { ajax, URLS, errorHandlers } = this.salesChannelsService;

        const url = URLS.PATCH_CHANNEL_PATTERN_V2.replace(
            ChannelPatterns.channelIdField,
            channelId,
        ).replace(ChannelPatterns.patternCodeField, patternCode);

        const config = {
            headers: HttpPatchHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.patch,
        };

        await ajax.patch({
            url,
            config,
            errorConfig,
            data: mapPatternUpdateToAPI(data),
        });

        this.updatePatternAlert(data);
    }

    private async importChannelPatternData(
        file: unknown,
        channelId: string,
        type: 'categories' | 'tags' | 'titles',
    ): Promise<{ job_id: string }> {
        const {
            ajax,
            errorHandlers,
            mediaFiles: { uploadCustomFiles },
        } = this.salesChannelsService;

        const { id: uploadedFileId } = await uploadCustomFiles(file);

        const url = this.UPLOAD_URLS[type].replace(
            ChannelPatterns.channelIdField,
            channelId,
        );

        const response: { data: { job_id: string } } = await ajax.post({
            url,
            errorConfig: {
                addGenericAlert: false,
                throwError: errorHandlers.post,
            },
            config: {
                headers: ApplicationJsonHeaders,
            },
            data: {
                media_file: {
                    id: `custom-files/${uploadedFileId}`,
                },
                separator: ',',
                enclosure: '"',
            },
        });

        return response.data;
    }

    importCategories(
        file: unknown,
        channelId: string,
    ): Promise<{ job_id: string }> {
        return this.importChannelPatternData(file, channelId, 'categories');
    }

    importTags(file: unknown, channelId: string): Promise<{ job_id: string }> {
        return this.importChannelPatternData(file, channelId, 'tags');
    }

    importTitles(
        file: unknown,
        channelId: string,
    ): Promise<{ job_id: string }> {
        return this.importChannelPatternData(file, channelId, 'titles');
    }

    async postChannelPatterns(
        channelId: string,
        params: IServiceMethodParams<unknown, unknown> = {},
        configFilters: any,
    ) {
        const { ajax, URLS } = this.salesChannelsService;

        const {
            page = 1,
            per_page: itemsPerPage = 20,
            filters = {},
            sort = {},
        } = params;

        const postFilters = parsePostFilters({
            filters: {
                ...filters,
                channelId,
            },
            sort,
            config: configFilters,
        });

        const { data } = await ajax.post({
            url: URLS.POST_CHANNEL_PATTERNS,
            data: postFilters,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    page,
                    itemsPerPage,
                },
            },
        });

        return {
            items: data?.results?.map(mapTranslationPatternFromApi) ?? [],
            itemsTotal: data?.total ?? 0,
        };
    }
}
