import styled from '@emotion/styled';

export const CharacterCounterStyled = styled.div`
    text-align: right;
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.palette.text.disabled};
    margin-bottom: -10px;
`;
