import React from 'react';
import { Autocomplete as AutocompleteMUI, TextField } from '@mui/material';
import { AUTOCOMPLETE_FIELD_CLASS } from './autocompleteClasses';
import { AutocompleteChip } from '../AutocompleteChip/AutocompleteChip';
import { useTranslation } from 'react-i18next';
import Loader from '@mui/material/CircularProgress';
import type { AutocompleteBaseProps, Option } from './autocompleteBase.types';

export const AutocompleteBase = <TName extends string>({
    id,
    label,
    handleChange,
    handleBlur,
    margin,
    value,
    className,
    error,
    onInputChange,
    size = 'medium',
    options = [],
    fullWidth = false,
    disabled = false,
    readOnly = false,
    multiple = false,
    disableCloseOnSelect,
    disableClearable = false,
    loading = false,
    maxLength,
    groupBy,
    isOptionEqualToValue,
    getOptionLabel,
    filterOptions,
    renderTags,
    customRenderOptionLabel = (option) =>
        typeof option === 'object' && option !== null && 'label' in option
            ? (option as Option).label
            : String(option),
}: AutocompleteBaseProps<TName>) => {
    const { t } = useTranslation();

    if (multiple && value !== undefined && !Array.isArray(value)) {
        throw new Error(
            'AutocompleteBase - Passed value for multiple should be an array',
        );
    }

    return (
        <AutocompleteMUI
            id={id}
            className={className}
            fullWidth={fullWidth}
            disableClearable={disableClearable}
            options={options}
            onChange={(_e, items: Option | Option[] | null, reason) =>
                handleChange(id, items, options, reason)
            }
            onBlur={handleBlur}
            readOnly={readOnly}
            disabled={disabled}
            disableCloseOnSelect={
                disableCloseOnSelect !== undefined
                    ? disableCloseOnSelect
                    : multiple
            }
            value={value}
            loadingText={t('common:glossary.loading')}
            groupBy={groupBy}
            filterOptions={filterOptions}
            renderOption={(props, option: Option) => (
                <li
                    {...props}
                    key={option.value}
                >
                    {customRenderOptionLabel(option)}
                </li>
            )}
            noOptionsText={t('common:alerts.noOptions')}
            multiple={multiple}
            onInputChange={onInputChange}
            getOptionLabel={getOptionLabel}
            loading={loading}
            isOptionEqualToValue={
                isOptionEqualToValue
                    ? isOptionEqualToValue
                    : (option, val) =>
                          (option as Option).value === (val as Option).value
            }
            size={size}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    name={id}
                    className={AUTOCOMPLETE_FIELD_CLASS}
                    margin={margin}
                    error={!!error}
                    helperText={error}
                    InputLabelProps={{
                        sx: {
                            // Denial of accessibility, but consistent at ARTE.
                            color: ({ palette }) => palette.grey[600],
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        inputProps: {
                            ...params.inputProps,
                            maxLength,
                        },
                        endAdornment: (
                            <>
                                {loading ? (
                                    <Loader
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderTags={
                renderTags
                    ? renderTags
                    : (value: Option[], getTagProps) => (
                          <AutocompleteChip
                              value={value}
                              getTagProps={getTagProps}
                              getOptionLabel={getOptionLabel}
                          />
                      )
            }
        />
    );
};
