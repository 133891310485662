import React from 'react';
import {
    BreadcrumbsStyled,
    SeparatorStyled,
    StageNameStyled,
} from 'components/Breadcrumbs/breadcrumbs.styles';

interface BreadcrumbsProps {
    overwrite?: Record<number, string>;
    moduleName?: string;
    displayIndex?: number;
    stagedPath?: string[];
    stagedPathCustom?: string[];
    skipPath?: number[];
    t?: (key: string, options?: { defaultValue?: string }) => string;
    moveTo?: (link: string) => void;
}

const Breadcrumbs = ({
    overwrite = {},
    moduleName = '',
    displayIndex = 1,
    stagedPath: stagedPathRedux = [],
    stagedPathCustom,
    skipPath = [],
    t = (key) => key,
    moveTo = () => {},
}: BreadcrumbsProps) => {
    const stagedPath = stagedPathCustom || stagedPathRedux;
    const lastIndex = stagedPath.length - 1;

    const handleMoveTo = (index: number) => {
        const link = '/' + stagedPath.slice(0, index + 1).join('/');

        moveTo(link);
    };

    return (
        <BreadcrumbsStyled>
            {stagedPath.map((stage, index) => {
                if (index >= displayIndex && !skipPath.includes(index)) {
                    const stageProps = {
                        onClick: () => index < lastIndex && handleMoveTo(index),
                        tabIndex: index < lastIndex ? 0 : undefined,
                        'aria-disabled': index === lastIndex,
                        disabled: index === lastIndex,
                        'data-test': 'stage',
                    };

                    return (
                        <span key={index}>
                            {index > displayIndex && (
                                <SeparatorStyled data-test="separator">
                                    {' / '}
                                </SeparatorStyled>
                            )}
                            <StageNameStyled {...stageProps}>
                                {overwrite[index] ||
                                    t(`${moduleName}:nav.${stage}`, {
                                        defaultValue: stage,
                                    })}
                            </StageNameStyled>
                        </span>
                    );
                }

                return null;
            })}
        </BreadcrumbsStyled>
    );
};

export default Breadcrumbs;
