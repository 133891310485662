/* eslint-disable sonarjs/no-duplicate-string */
import type { ProdisService } from 'services/prodis/prodisService';
import { paramsSerializer } from '../../../utils/utils';
import type { AjaxService } from '../../../ajax/AjaxService';
import type { default as ParserService } from 'qs';
import type { AlertService } from '../../../alert/AlertService';
import type { MediaFilesService } from '../../../mediaFiles/MediaFilesService';
import type { URLS as UrlsConfig } from 'services/prodis/constants';
import { omitEmptyOrNegative } from '../../../../utils/filters';
import {
    mapCustomShipmentDocumentsFromApi,
    mapCustomShipmentsFromApi,
} from '../../mappers/prodis';
import type {
    CustomShipmentDocument,
    CustomShipmentOutputDto,
    CustomShipmentRead,
    CustomShipmentsResponse,
    ICustomShipmentPatch,
} from './customShipment.type';
import { ApplicationJsonHeaders } from 'services/constants';

export class CustomShipmentActions {
    private static instance: CustomShipmentActions | undefined;

    private constructor(
        private readonly prodisService: ProdisService,
        private readonly services: {
            ajax: AjaxService;
            parser: typeof ParserService;
            alert: AlertService;
            mediaFiles: MediaFilesService;
        },
        private readonly URLS: typeof UrlsConfig,
    ) {
        this.getCustomShipmentsList = this.getCustomShipmentsList.bind(this);
        this.downloadCustomShipmentDocument =
            this.downloadCustomShipmentDocument.bind(this);
        this.getCustomShipmentByWZServiceCode =
            this.getCustomShipmentByWZServiceCode.bind(this);
        this.deleteCustomShipmentByWZServiceCode =
            this.deleteCustomShipmentByWZServiceCode.bind(this);
        this.downloadWZDocument = this.downloadWZDocument.bind(this);
        this.generateCustomShipment = this.generateCustomShipment.bind(this);
        this.editCustomShipment = this.editCustomShipment.bind(this);
        this.uploadCustomShipmentDocument =
            this.uploadCustomShipmentDocument.bind(this);
    }

    static getInstance(
        prodisService: ProdisService,
        services: {
            ajax: AjaxService;
            parser: typeof ParserService;
            alert: AlertService;
            mediaFiles: MediaFilesService;
        },
        URLS: typeof UrlsConfig,
    ): CustomShipmentActions {
        if (!CustomShipmentActions.instance) {
            CustomShipmentActions.instance = new CustomShipmentActions(
                prodisService,
                services,
                URLS,
            );
        }

        return CustomShipmentActions.instance;
    }

    async getCustomShipmentsList({
        filters = {},
        per_page = 20,
        page = 1,
        sort = {},
    }: {
        per_page?: number;
        page?: number;
        sort: unknown;
        filters?: {
            stabloOrderIds?: string;
            trackingNumber?: string;
            status?: string;
            carrierCode?: string | string[];
            wzServiceName?: string;
            wzServiceCreateDate?: [string, string];
        };
    }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                order: sort,
                stabloOrderIds: filters.stabloOrderIds,
                trackingNumber: filters.trackingNumber,
                status: filters.status,
                carrierCode: filters.carrierCode,
                wzServiceName: filters.wzServiceName,
                wzServiceCreateDate:
                    filters.wzServiceCreateDate?.length &&
                    omitEmptyOrNegative({
                        after: filters.wzServiceCreateDate[0] ?? '',
                        before: filters.wzServiceCreateDate[1] ?? '',
                    }),
            },
            paramsSerializer,
            headers: ApplicationJsonHeaders,
        };

        try {
            const response: { data: CustomShipmentsResponse } = await ajax.get({
                url: this.URLS.GET_CUSTOM_SHIPMENTS,
                config,
                onError: errorHandlers.get,
                errorConfig: { onError: errorHandlers.get },
            });

            const items = response?.data?.results || [];
            const itemsTotal = response?.data?.total || 0;

            return {
                items: items.map(mapCustomShipmentsFromApi),
                itemsTotal,
            };
        } catch {
            return {
                items: [],
                itemsTotal: 0,
            };
        }
    }

    downloadCustomShipmentDocument(id: string, fileName: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url = this.URLS.GET_DOWNLOAD_CUSTOM_SHIPMENT_DOCUMENT.replace(
            '{id}',
            id,
        );
        const config = {
            headers: {
                Accept: '*/*',
            },
        };
        const errorConfig = {
            throwError: errorHandlers.download,
            addGenericAlert: false,
            onError: errorHandlers.download,
        };

        return ajax.download({
            url,
            fileName,
            config,
            errorConfig,
            onError: errorHandlers.download,
        });
    }

    async getCustomShipmentByWZServiceCode(wzServiceCode: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url = this.URLS.GET_CUSTOM_SHIPMENT.replace(
            '{wzServiceCode}',
            wzServiceCode,
        );

        const response: { data: CustomShipmentOutputDto } = await ajax.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
            },
            onError: errorHandlers.get,
            errorConfig: { onError: errorHandlers.get },
        });

        return {
            ...response.data,
            documents: response.data.documents.map(
                mapCustomShipmentDocumentsFromApi,
            ),
        };
    }

    async deleteCustomShipmentByWZServiceCode(
        wzServiceCode: string,
    ): Promise<void> {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url = this.URLS.DELETE_CUSTOM_SHIPMENT.replace(
            '{wzServiceCode}',
            wzServiceCode,
        );

        await ajax.delete({
            url,
            config: {},
            onError: errorHandlers.delete,
            errorConfig: { onError: errorHandlers.delete },
        });
    }

    async downloadWZDocument(wzServiceCode: string): Promise<string> {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url = this.URLS.GET_EXPORT_CUSTOM_SHIPMENT.replace(
            '{wzServiceCode}',
            wzServiceCode,
        );

        const response = await ajax.get({
            url,
            errorConfig: { onError: errorHandlers.download },
            onError: errorHandlers.download,
            config: {
                headers: {
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            },
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });

        return window.URL.createObjectURL(blob);
    }

    async generateCustomShipment(payload: {
        date: string;
        contractorId: number;
    }): Promise<void> {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url = this.URLS.POST_GENERATE_CUSTOM_SHIPMENT;

        await ajax.post({
            url,
            errorConfig: { onError: errorHandlers.post },
            onError: errorHandlers.post,
            data: payload,
            config: {
                headers: ApplicationJsonHeaders,
            },
        });
    }

    async editCustomShipment(
        wzServiceCode: CustomShipmentOutputDto['wzServiceCode'],
        payload: ICustomShipmentPatch,
    ): Promise<CustomShipmentRead> {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url = this.URLS.PATCH_CUSTOM_SHIPMENT.replace(
            '{wzServiceCode}',
            wzServiceCode,
        );

        const response: { data: CustomShipmentRead } = await ajax.patch({
            url,
            errorConfig: { onError: errorHandlers.patch },
            onError: errorHandlers.patch,
            data: payload,
            config: {
                headers: ApplicationJsonHeaders,
            },
        });

        return response.data;
    }

    async uploadCustomShipmentDocument(
        wzServiceCode: CustomShipmentOutputDto['wzServiceCode'],
        path: string,
    ): Promise<CustomShipmentDocument> {
        const { ajax } = this.services;
        const { errorHandlers } = this.prodisService;

        const url =
            this.URLS.POST_CUSTOM_SHIPMENT_CUSTOM_SHIPMENT_DOCUMENT.replace(
                '{wzServiceCode}',
                wzServiceCode,
            );

        const response: { data: CustomShipmentDocument } = await ajax.post({
            url,
            errorConfig: { onError: errorHandlers.post },
            onError: errorHandlers.post,
            data: { path },
            config: {
                headers: ApplicationJsonHeaders,
            },
        });

        return response.data;
    }
}
