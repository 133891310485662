import get from 'lodash/get';
import {
    mapCreateSalesChannelToApi,
    mapEditSalesChannelToApi,
    mapSalesChannelFromApi,
} from '../../mappers/salesChannel';
import { configError } from 'services/utils/utils';
import { ApplicationJsonHeaders } from 'services/constants';
import { mapIntermediateProductFromApi } from 'services/salesChannels/mappers/offer';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';
import type { SalesChannelsService } from '../../SalesChannelsService';
import { omitEmptyOrNegative } from '../../../../utils/filters';
import { ChannelExportFeedDto } from './sales-channels.service.type';

class SalesChannels {
    private readonly params: SalesChannelsService['SALES_CHANNELS_PARAMS'];
    private readonly errorHandlers: SalesChannelsService['errorHandlers'];
    private readonly asyncRequestPaginator: AsyncRequestPaginator<unknown>;

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.params = salesChannelsService.SALES_CHANNELS_PARAMS;
        this.errorHandlers = salesChannelsService.errorHandlers;

        this.getById = this.getById.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.exportFeed = this.exportFeed.bind(this);
        this.getList = this.getList.bind(this);
        this.getOfferIntermediateProducts =
            this.getOfferIntermediateProducts.bind(this);
        this.getMoreOfferIntermediateProducts =
            this.getMoreOfferIntermediateProducts.bind(this);
        this.getAllOfferIntermediateProducts =
            this.getAllOfferIntermediateProducts.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            this.getMoreOfferIntermediateProducts,
            mapIntermediateProductFromApi,
        );
    }

    async getById(id) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_CHANNEL.replace('{id}', id);
        const errorConfig = configError('get', this.errorHandlers);

        const { data = {} } = await ajax.get({ url, errorConfig });

        return mapSalesChannelFromApi(data);
    }

    async getList(
        params: {
            page?: number | undefined;
            per_page?: number | undefined;
            order?: string | undefined;
            filters?: Record<string, unknown> | undefined;
        } = {},
    ) {
        const {
            ajax,
            URLS,
            DEFAULTS,
            getSortParams,
            getFilterParams,
            paramsSerializer,
        } = this.salesChannelsService;
        const { SORTING_KEYS, FILTERS_KEYS } = this.params;
        const {
            page = 1,
            per_page = DEFAULTS.SALES_CHANNELS_PER_PAGE,
            order: orderFromUrl,
            filters: filtersFromUrl,
        } = params;

        const url = URLS.GET_CHANNELS;
        const sort = getSortParams(SORTING_KEYS, orderFromUrl);
        const filter = getFilterParams(FILTERS_KEYS, filtersFromUrl) || {};
        const errorConfig = configError('get', this.errorHandlers);
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                ...(sort && { order: sort }),
                ...(filter.search && { match: filter.search }),
            },
            paramsSerializer,
        };

        const response = await ajax.get({ url, config, errorConfig });
        const itemsCount = get(response, 'data.hydra:totalItems', 0);
        const items = get(response, 'data.hydra:member', []).map((channel) =>
            mapSalesChannelFromApi(channel),
        );

        return { items, itemsCount };
    }

    async create(formData) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.POST_CHANNEL;
        const errorConfig = configError('post', this.errorHandlers);

        return await ajax.post({
            url,
            errorConfig,
            data: omitEmptyOrNegative(mapCreateSalesChannelToApi(formData)),
        });
    }

    async edit({ formData, id }) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.PATCH_CHANNEL.replace('{id}', id);
        const errorConfig = configError('patch', this.errorHandlers);
        const config = {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        };

        return await ajax.patch({
            url,
            config,
            errorConfig,
            data: mapEditSalesChannelToApi(formData),
        });
    }

    async getAllOfferIntermediateProducts(params = {}) {
        return this.asyncRequestPaginator.fetchFullList(params);
    }

    async getOfferIntermediateProducts(params = {}) {
        const { data } = await this.getMoreOfferIntermediateProducts(params);

        return {
            items: (data?.results || []).map(mapIntermediateProductFromApi),
            totalItems: data?.total || 0,
        };
    }

    getMoreOfferIntermediateProducts(
        params: {
            page?: number | undefined;
            per_page?: number | undefined;
            filters?:
                | {
                      name?: string | undefined;
                      index?: string | undefined;
                      technology?: { id: string } | undefined;
                      partsCount?: string | undefined;
                      categoryName?: string | undefined;
                      widthFrom?: string | undefined;
                      heightFrom?: string | undefined;
                  }
                | undefined;
            channelId?: string | undefined;
            offerId?: string | undefined;
        } = {},
    ) {
        const { ajax, URLS, HEADERS, paramsSerializer, DEFAULTS } =
            this.salesChannelsService;

        const {
            filters,
            page = 1,
            per_page = DEFAULTS.SALES_CHANNELS_PER_PAGE,
            channelId,
            offerId,
        } = params;

        const filterParams = {
            ...(filters?.name && { name: filters?.name }),
            ...(filters?.index && { code: filters?.index }),
            ...(filters?.technology?.id && {
                technology_id: filters?.technology?.id,
            }),
            ...(filters?.partsCount && {
                parts_count: parseInt(filters.partsCount),
            }),
            ...(filters?.categoryName && {
                category_definition_id:
                    filters.categoryName === 'none'
                        ? null
                        : filters.categoryName,
            }),
            ...(filters?.widthFrom && {
                width_from: parseInt(filters.widthFrom) * 10,
            }),
            ...(filters?.heightFrom && {
                height_from: parseInt(filters.heightFrom) * 10,
            }),
        };

        const url = URLS.GET_CHANNEL_OFFER_INTERMEDIATE_PRODUCTS.replace(
            '{channelId}',
            channelId,
        ).replace('{id}', offerId);

        const config = {
            headers: HEADERS.basic,
            params: {
                page,
                itemsPerPage: per_page,
                ...filterParams,
            },
            paramsSerializer,
        };

        const errorConfig = configError('get', this.errorHandlers);

        return ajax.get({ url, config, errorConfig });
    }

    async exportFeed(
        channelId: string,
        channelExportFeedDto: ChannelExportFeedDto,
    ) {
        const { ajax, URLS } = this.salesChannelsService;

        const url = URLS.POST_CHANNEL_FEED_EXPORT.replace('{id}', channelId);
        const errorConfig = configError('post', this.errorHandlers);
        const config = { headers: ApplicationJsonHeaders };

        const response: { data: { job_id: string } } = await ajax.post({
            url,
            config,
            errorConfig,
            data: {
                ...channelExportFeedDto,
                note: channelExportFeedDto.note || null,
                unitOfMeasure:
                    channelExportFeedDto?.unitOfMeasure ?? 'centimeter',
            },
        });

        return response.data;
    }
}

export default SalesChannels;
