import { AutocompleteBase } from 'components/AutocompleteBase/AutocompleteBase';
import type { FetchAutocompleteProps } from './fetchAutocomplete.type';
import { useFetchAutocompleteOptions } from './useFetchAutocompleteOptions.hook';
import {
    blurHandler,
    changeHandler,
    inputChangeHandler,
    isOptionEqualToValue,
    getOptionLabel,
    filterOptions,
} from './fetchAutocomplete.utils';
import { AutocompleteChip } from 'components/AutocompleteChip/AutocompleteChip';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { default as debounceUtil } from 'lodash/debounce';
import { DEFAULT_DEBOUNCE_DELAY } from 'services/constants';

export const FetchAutocomplete = <
    TName extends string,
    T extends { value: unknown; label: string },
>({
    id,
    fetchOptions,
    localFilter = false,
    multiple,
    handleChange,
    value,
    handleBlur,
    gcTime,
    staleTime,
    cacheKey,
    updateOnValueChange = false,
    keepPreviousOptions = true,
    debounce = true,
    ...rest
}: FetchAutocompleteProps<TName, T>) => {
    const defaultValue = multiple ? [] : null;

    const { isFetching, setSearch, options, optionsMap } =
        useFetchAutocompleteOptions(
            id,
            value,
            fetchOptions,
            gcTime,
            staleTime,
            cacheKey,
            updateOnValueChange,
            keepPreviousOptions,
            multiple,
        );

    const internal_delayedSearchSetter = useRef(
        debounceUtil(setSearch, DEFAULT_DEBOUNCE_DELAY),
    ).current;

    const delayedSearchSetter = useCallback(
        (...parameters: Parameters<typeof internal_delayedSearchSetter>) => {
            internal_delayedSearchSetter.cancel();

            internal_delayedSearchSetter(...parameters);
        },
        [internal_delayedSearchSetter],
    );

    useEffect(() => {
        return () => {
            internal_delayedSearchSetter.cancel();
        };
    }, [internal_delayedSearchSetter]);

    const searchSetter = useMemo(
        () => (debounce ? delayedSearchSetter : setSearch),
        [debounce, delayedSearchSetter, setSearch],
    );

    const defaultValueToSet = value || defaultValue;

    return (
        <AutocompleteBase
            {...rest}
            id={id}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel(options)}
            filterOptions={filterOptions(options)}
            loading={isFetching}
            options={options}
            multiple={multiple}
            handleChange={changeHandler(
                id,
                handleChange,
                searchSetter,
                multiple,
            )}
            handleBlur={blurHandler(searchSetter, handleBlur)}
            value={defaultValueToSet}
            onInputChange={inputChangeHandler(localFilter, searchSetter)}
            renderTags={
                multiple
                    ? (selectedValues: T['value'][], getTagProps) => (
                          <AutocompleteChip
                              value={selectedValues
                                  .map(
                                      (selectedValue) =>
                                          optionsMap.current.get(
                                              String(selectedValue),
                                          ) ??
                                          options.find(
                                              (option) =>
                                                  option.value ===
                                                  selectedValue,
                                          ),
                                  )
                                  .filter((option) => !!option)}
                              getTagProps={getTagProps}
                          />
                      )
                    : undefined
            }
        />
    );
};
