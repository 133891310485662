import {
    mapEditBillingAddressToApi,
    mapEditShippingAddressToApi,
    mapOrderBalanceFromApi,
    mapOrderFromApi,
    mapOrdersImportToAPI,
    postOrdersSearchFilters,
} from '../../mappers/order';
import { ApplicationJsonHeaders } from 'services/constants';
import { isSimpArtgeistOrder } from 'modules/production/utils/productionOrders';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { MediaFilesService } from 'services/mediaFiles/MediaFilesService';
import {
    type URLS as URLS_CONFIG,
    type DEFAULTS as DEFAULTS_CONFIG,
} from '../../constants';
import type {
    IOrderLine,
    IOrderOutputDto,
    IOrderTransactionsOutputDto,
    IPostOrdersSearchFilters,
    IPostOrdersSearchResponseItem,
    IPostOrdersSearchSort,
} from './order.type';
import qs from 'qs';
import {
    AjaxResponse,
    ListResponse,
    AsyncRequestPaginator,
} from 'services/utils/AsyncRequestPaginator';
import { TOrderCompany } from 'types/orderCompany.type';
import {
    IPagedResponse,
    IServiceMethodParams,
} from '../../../../types/services.type';

const hydraMember = 'hydra:member';

class Order {
    constructor(
        private readonly ajaxService: AjaxService,
        private readonly mediaFiles: MediaFilesService,
        private readonly URLS: typeof URLS_CONFIG,
        private readonly DEFAULTS: typeof DEFAULTS_CONFIG,
    ) {
        this.getList = this.getList.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.getOrderInvoices = this.getOrderInvoices.bind(this);
        this.getOrderBalance = this.getOrderBalance.bind(this);
        this.editBillingAddress = this.editBillingAddress.bind(this);
        this.editShippingAddress = this.editShippingAddress.bind(this);
        this.importOrders = this.importOrders.bind(this);
        this.addNote = this.addNote.bind(this);
        this.getNotesForOrderLine = this.getNotesForOrderLine.bind(this);
        this.addNoteToOrderLine = this.addNoteToOrderLine.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.updateUseArteOffer = this.updateUseArteOffer.bind(this);
        this.getAllOrderLines = this.getAllOrderLines.bind(this);
        this.patchValidationCompleted =
            this.patchValidationCompleted.bind(this);
        this.getOrderLines = this.getOrderLines.bind(this);
        this.postOrdersSearch = this.postOrdersSearch.bind(this);
    }

    async getList(
        params: Partial<{
            page: number;
            perPage: number;
            filters: Record<string, unknown>;
        }> = {},
    ) {
        const { ajaxService, URLS, DEFAULTS } = this;
        const {
            page = 1,
            perPage = DEFAULTS.ORDERS_PER_PAGE,
            filters,
        } = params;

        const url = URLS.GET_ORDERS;
        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page,
                itemsPerPage: perPage,
                'order[updatedAt]': 'desc',
                ...filters,
            },
        };

        const response = await ajaxService.get({ url, config });

        return {
            items: response.data.results.map(mapOrderFromApi),
            totalItems: response.data.total,
        };
    }

    async getOrder(
        orderId,
        simpCompany = 'artgeist',
        ignoreErrors = false,
    ): Promise<IOrderOutputDto> {
        const { ajaxService, URLS } = this;
        const baseUrl = isSimpArtgeistOrder(simpCompany)
            ? URLS.GET_ORDER
            : URLS.DECO_GET_ORDER;
        const url = baseUrl.replace('{id}', orderId);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = ignoreErrors
            ? {
                  logError: false,
                  addGenericAlert: false,
                  errorHandler: () => {},
              }
            : {};

        const { data } = await ajaxService.get({ url, config, errorConfig });

        return data;
    }

    async getOrderLines(
        orderId: string,
        company: TOrderCompany | undefined = 'artgeist',
        { page, itemsPerPage }: { page: number; itemsPerPage: number } = {
            page: 1,
            itemsPerPage: 50,
        },
    ): Promise<
        AjaxResponse<
            ListResponse<IOrderLine> & { page: number; itemsPerPage: number }
        >
    > {
        const { ajaxService, URLS } = this;
        const baseURL =
            company === 'artgeist'
                ? URLS.GET_ORDER_ORDER_LINES
                : URLS.DECO_GET_ORDER_ORDER_LINES;
        const url = baseURL.replace('{id}', orderId);

        return ajaxService.get({
            url,
            config: {
                params: {
                    page,
                    itemsPerPage,
                },
                paramsSerializer: qs.stringify,
                headers: ApplicationJsonHeaders,
            },
        });
    }

    async getAllOrderLines(
        orderId: string,
        company: TOrderCompany | undefined = 'artgeist',
    ) {
        const orderLinesPaginator = new AsyncRequestPaginator<
            IOrderLine,
            IOrderLine
        >(
            (pageParams) =>
                this.getOrderLines(orderId, company, {
                    page: pageParams.page as number,
                    itemsPerPage:
                        typeof pageParams.itemsPerPage === 'number'
                            ? pageParams.itemsPerPage
                            : 50,
                }),
            (data) => data,
        );

        const data = await orderLinesPaginator.fetchFullList({
            itemsPerPage: 50,
        });

        return data.items;
    }

    async getOrderInvoices(orderId, company = 'artgeist') {
        const { ajaxService, URLS } = this;
        const baseURL =
            company === 'artgeist'
                ? URLS.GET_ORDER_INVOICES
                : URLS.DECO_GET_ORDER_INVOICES;
        const url = baseURL.replace('{id}', orderId);

        const response = await ajaxService.get({ url });

        return response.data[hydraMember];
    }

    async getOrderBalance(orderId, simpCompany = 'artgeist') {
        const { ajaxService, URLS } = this;
        const baseUrl = isSimpArtgeistOrder(simpCompany)
            ? URLS.GET_ORDER_BALANCE
            : URLS.DECO_GET_ORDER_BALANCE;
        const url = baseUrl.replace('{id}', orderId);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data } = await ajaxService.get({ url, config });

        return mapOrderBalanceFromApi(data);
    }

    async getOrderTransactions(
        orderId: string,
        simpCompany: 'artgeist' | 'decomonkey' = 'artgeist',
    ): Promise<IOrderTransactionsOutputDto> {
        const { ajaxService, URLS } = this;
        const baseUrl = isSimpArtgeistOrder(simpCompany)
            ? URLS.GET_ORDER_TRANSACTIONS
            : URLS.DECO_GET_ORDER_TRANSACTIONS;
        const url = baseUrl.replace('{id}', orderId);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data } = await ajaxService.get({ url, config });

        return data;
    }

    async editBillingAddress({ orderId, data }, company = 'artgeist') {
        const { ajaxService, URLS } = this;
        const url = (
            company === 'artgeist'
                ? URLS.PUT_ORDER_BILLING_ADDRESS
                : URLS.DECO_PUT_ORDER_BILLING_ADDRESS
        ).replace('{id}', orderId);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = {
            addGenericAlert: false,
        };

        return await ajaxService.put({
            url,
            config,
            errorConfig,
            data: mapEditBillingAddressToApi(data),
        });
    }

    async editShippingAddress({ orderId, data }, company = 'artgeist') {
        const { ajaxService, URLS } = this;
        const url = (
            company === 'artgeist'
                ? URLS.PUT_ORDER_SHIPPING_ADDRESS
                : URLS.DECO_PUT_ORDER_SHIPPING_ADDRESS
        ).replace('{id}', orderId);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = {
            addGenericAlert: false,
        };

        return await ajaxService.put({
            url,
            config,
            errorConfig,
            data: mapEditShippingAddressToApi(data),
        });
    }

    async importOrders(data, company = 'artgeist') {
        const {
            ajaxService,
            URLS,
            mediaFiles: { uploadCustomFiles },
        } = this;
        const url =
            company === 'artgeist'
                ? URLS.POST_IMPORT_ORDERS
                : URLS.DECO_POST_IMPORT_ORDERS;

        const { id } = await uploadCustomFiles(data.file.file);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        return ajaxService.post({
            url,
            config,
            data: mapOrdersImportToAPI({ ...data, file: id }),
        });
    }

    async addNote(orderId, note, company = 'artgeist') {
        const { ajaxService, URLS } = this;

        const baseURL =
            company === 'artgeist'
                ? URLS.POST_ORDER_NOTE
                : URLS.DECO_POST_ORDER_NOTE;

        return ajaxService.post({
            url: baseURL.replace('{id}', orderId),
            config: { headers: ApplicationJsonHeaders },
            data: { note },
        });
    }

    async getNotesForOrderLine(
        orderLineId: string,
        company: TOrderCompany | undefined = 'artgeist',
    ) {
        const { ajaxService, URLS } = this;

        const baseURL =
            company === 'artgeist'
                ? URLS.GET_ORDER_LINE_NOTES
                : URLS.DECO_GET_ORDER_LINE_NOTES;

        return ajaxService
            .get({
                url: baseURL.replace('{id}', orderLineId),
                config: { headers: ApplicationJsonHeaders },
            })
            .then(({ data }) => data);
    }

    async addNoteToOrderLine(
        orderLineId: string,
        note: string,
        informProduction = false,
        company: TOrderCompany | undefined = 'artgeist',
    ) {
        const { ajaxService, URLS } = this;

        const baseURL =
            company === 'artgeist'
                ? URLS.POST_ORDER_LINE_NOTE
                : URLS.DECO_POST_ORDER_LINE_NOTE;

        return ajaxService.post({
            url: baseURL.replace('{id}', orderLineId),
            config: {
                headers: ApplicationJsonHeaders,
            },
            data: { note, informProduction },
        });
    }

    async cancelOrder(orderId, company = 'artgeist') {
        const { ajaxService, URLS } = this;

        const baseURL =
            company === 'artgeist'
                ? URLS.PATCH_REQUEST_ORDER_CANCELLATION
                : URLS.DECO_PATCH_REQUEST_ORDER_CANCELLATION;

        return ajaxService.patch({
            url: baseURL.replace('{id}', orderId),
            config: { headers: ApplicationJsonHeaders },
            data: {},
        });
    }

    async updateUseArteOffer(
        orderID: string,
        company: TOrderCompany | undefined,
        useArteOffer = false,
    ) {
        const { ajaxService, URLS } = this;
        const baseURL =
            company === 'artgeist'
                ? URLS.PATCH_ORDER_USE_ARTE_OFFER
                : URLS.DECO_PATCH_ORDER_USE_ARTE_OFFER;

        return ajaxService.patch({
            url: baseURL.replace('{id}', orderID),
            config: { headers: ApplicationJsonHeaders },
            errorConfig: { addGenericAlert: false },
            data: { useArteOffer },
        });
    }

    async patchValidationCompleted(
        orderID: string,
        company: TOrderCompany | undefined,
    ) {
        const { ajaxService, URLS } = this;
        const baseURL =
            company === 'artgeist'
                ? URLS.PATCH_VALIDATION_COMPLETED
                : URLS.DECO_PATCH_VALIDATION_COMPLETED;

        return ajaxService.patch({
            url: baseURL.replace('{id}', orderID),
            config: { headers: ApplicationJsonHeaders },
            errorConfig: { addGenericAlert: false },
        });
    }

    async postOrdersSearch({
        page: pageParam,
        per_page,
        filters,
        sort,
    }: IServiceMethodParams<
        IPostOrdersSearchFilters,
        IPostOrdersSearchSort
    >): Promise<IPagedResponse<IPostOrdersSearchResponseItem>> {
        const { ajaxService, URLS } = this;
        const url =
            filters?.company === 'decomonkey'
                ? URLS.DECO_POST_ORDERS_SEARCH
                : URLS.POST_ORDERS_SEARCH;

        const filter = postOrdersSearchFilters(filters);

        const {
            data: { page, itemsPerPage, totalPages, total, results },
        } = await ajaxService.post({
            url,
            config: {
                headers: ApplicationJsonHeaders,
                paramsSerializer: qs.stringify,
                params: { page: pageParam, itemsPerPage: per_page },
            },
            data: {
                ...(Object.keys(filter).length > 0 ? { filter } : {}),
                ...(Object.keys(sort).length > 0 ? { sort } : {}),
            },
        });

        return {
            results,
            total,
            page,
            itemsPerPage,
            totalPages,
        };
    }
}

export default Order;
