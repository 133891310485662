import capitalize from 'lodash/capitalize';
import { omitEmptyOrNegative } from 'utils/filters';
import { formatContractorLabel, formatCountryLabel, formatDate } from 'utils/formatting';

const mapCarrierRuleGetListOrderParams = ({ contractor, boxGroup, carrierType, country, ...sort }) => {
    return ({
        ...sort,
        ...(boxGroup && { 'boxGroup.code': boxGroup }),
        ...(carrierType && { 'carrierType.code': carrierType }),
        ...(country && { 'countryCode': country }),
        ...(contractor && { 'contractor.name': contractor }),
    });
};

export const mapCarrierRuleGetListFilters = ({
    boxGroup,
    contractor,
    carrierType,
    country,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    ...filters
}) =>
    omitEmptyOrNegative({
        ...filters,
        countryCode: country,
        'carrierType.code': carrierType,
        'boxGroup.id': boxGroup,
        'contractor.id': contractor,
        createdAt:
            (createdAtFrom || createdAtTo) &&
            omitEmptyOrNegative({
                after: createdAtFrom,
                before: createdAtTo,
            }),
        updatedAt:
            (updatedAtFrom || updatedAtTo) &&
            omitEmptyOrNegative({
                after: updatedAtFrom,
                before: updatedAtTo,
            }),
    });

export const mapCarrierRuleGetListParams = ({ per_page: itemsPerPage, sort, filters, page, ...params } = {}) => ({
    ...params,
    ...mapCarrierRuleGetListFilters(filters),
    page,
    itemsPerPage,
    order: mapCarrierRuleGetListOrderParams(sort),
});

export const mapCarrierRule = ({
    id,
    boxGroup,
    countryCode,
    carrierType,
    createdAt,
    updatedAt,
    contractor,
    company,
    producer,
}) => ({
    id,
    boxGroup: boxGroup?.code,
    carrierType: carrierType?.name,
    producer,
    company: capitalize(company),
    country: formatCountryLabel(countryCode),
    contractor: formatContractorLabel(contractor),
    createdAt: formatDate(createdAt),
    updatedAt: formatDate(updatedAt),
});

export const mapCarrierRuleGetList = ({ results, total }) => ({
    items: results.map(mapCarrierRule),
    totalItems: total,
});

export const mapFormForCarrierRule = ({
    carrierType,
    boxGroup,
    company,
    country,
    description,
    contractor,
    producer,
}) =>
    omitEmptyOrNegative({
        producer,
        carrierType: carrierType && {
            code: carrierType,
        },
        boxGroup: boxGroup && {
            id: boxGroup,
        },
        company,
        countryCode: country,
        contractor: contractor && {
            id: parseInt(contractor),
        },
        description,
    });

export const mapCarrierRuleBatch = (ids) => ({
    carrierRules: ids.map((id) => ({ id }))
});
