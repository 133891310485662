import moment from 'moment';
import { getUserFullName } from '../../utils/utils';

export const mapProductsFromApi = (product = {}) => ({
    id: product?.id,
    createdAt: moment(product?.createdAt).format('DD/MM/YYYY'),
    updatedAt: moment(product?.updatedAt).format('DD/MM/YYYY'),
    status: product?.status,
    intermediateProductId: product?.intermediateProduct?.id,
    intermediateProductCode: product?.intermediateProduct?.code,
    printSpecId: product?.pattern?.printSpec?.id,
    printSpecName: product?.pattern?.printSpec?.name,
    patternCode: product?.pattern?.code,
    createdBy: getUserFullName({
        firstName: product?.createdBy?.firstName,
        lastName: product?.createdBy?.lastName,
    }),
    updatedBy: getUserFullName({
        firstName: product?.updatedBy?.firstName,
        lastName: product?.updatedBy?.lastName,
    }),
});

export const mapProductsCSVListToApi = (object) => {
    const renameKeys = (object, newKeys) => {
        const keyValues = Object.keys(object).map((key) => {
            const newKey = newKeys[key] || key;

            return { [newKey]: object[key] };
        });

        return Object.assign({}, ...keyValues);
    };

    const newKeys = {
        offerId: 'offerIds',
        intermediateProductId: 'intermediateProductIds',
        intermediateProductTechnologyId: 'technologyIds',
        productId: 'productIds',
        patternCode: 'patternCodes',
        patternPrintSpecId: 'printSpecIds',
        status: 'statuses',
        createdAt: 'createdAt',
        externalProducerId: 'externalProducerIds',
    };

    return renameKeys(object, newKeys);
};
