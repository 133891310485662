import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        ORDER: {
            CREATE: `${MODULE_NAME}_resources_order_create`,
            EDIT: `${MODULE_NAME}_resources_order_edit`,
            SWITCH_ORDER: `${MODULE_NAME}_resources_update_use_arte_offer`,
        },
        ORDER_NOTES: {
            EDIT: `${MODULE_NAME}_resources_order_notes_edit`,
        },
        MESSAGE: {
            DISPLAY: `${MODULE_NAME}_resources_message_display`,
        },
    },
};
