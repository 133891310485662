import type { AxiosStatic } from 'axios';
import type { i18n } from 'i18next';
import type { AlertService } from 'services/alert/AlertService';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';
import { PRODUCTION_ENV_NAME } from 'utils/enviromentNames';
import {
    IDeleteParams,
    IDownloadParams,
    IGetParams,
    IPatchParams,
    IPostParams,
    IPutParams,
} from './ajaxService.type';

export class AjaxService {
    private readonly axios: AxiosStatic;
    private readonly authenticationService: AuthenticationService;
    private readonly alertService: AlertService;
    private readonly translator: i18n;

    private constructor({
        axios,
        authenticationService,
        alertService,
        translator,
    }: {
        axios: AxiosStatic;
        authenticationService: AuthenticationService;
        alertService: AlertService;
        translator: i18n;
    }) {
        this.axios = axios;
        this.alertService = alertService;
        this.authenticationService = authenticationService;
        this.translator = translator;

        this.sendRequest = this.sendRequest.bind(this);
        this.get = this.get.bind(this);
        this.delete = this.delete.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.patch = this.patch.bind(this);
        this.download = this.download.bind(this);
        this.defaultOnError = this.defaultOnError.bind(this);
    }

    private static instance: AjaxService | undefined;

    public static getInstance({
        axios,
        authenticationService,
        alertService,
        translator,
    }: {
        axios: AxiosStatic;
        authenticationService: AuthenticationService;
        alertService: AlertService;
        translator: i18n;
    }): AjaxService {
        if (!AjaxService.instance) {
            AjaxService.instance = new AjaxService({
                axios,
                authenticationService,
                alertService,
                translator,
            });
        }

        return AjaxService.instance;
    }

    private defaultOnError(error) {
        this.alertService.addGenericAlert(error);
        throw error;
    }

    private getFileNameFromContentDisposition(contentDisposition) {
        const filenameRegex = /filename\*=UTF-8''(.*)/i;
        const matches = filenameRegex.exec(contentDisposition);

        if (matches != null && matches[1]) {
            return decodeURI(matches[1]);
        }
    }

    sendRequest(method, request) {
        const token = this.authenticationService.getRawActiveToken();
        const {
            config = {},
            url,
            data,
            errorConfig = {},
            onError: errorHandler,
        } = request;
        const isProduction =
            window.env && window.env.APP_ENV === PRODUCTION_ENV_NAME;
        const {
            logError = true,
            addGenericAlert = true,
            addGenericFailureAlert = addGenericAlert,
            throwError = errorHandler
                ? errorHandler
                : addGenericFailureAlert
                  ? this.defaultOnError
                  : (error) => {
                        throw error;
                    },
        } = errorConfig;

        const requestData = {
            method,
            url,
            ...config,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
                Accept: 'application/ld+json',
                ...(config?.headers ?? {}),
            },
        };

        if (data) {
            requestData.data = data;
        }

        return this.axios
            .request(requestData)
            .then((responseAxios) => {
                return responseAxios;
            })
            .catch((error = {}) => {
                if (logError && !isProduction) {
                    // eslint-disable-next-line no-console
                    console.error(error, requestData);
                }
                throw throwError(error, requestData);
            });
    }

    get({ url, config, errorConfig, onError }: IGetParams) {
        return this.sendRequest('get', { config, url, errorConfig, onError });
    }

    delete({ url, config, errorConfig, onError }: IDeleteParams) {
        return this.sendRequest('delete', {
            config,
            url,
            errorConfig,
            onError,
        });
    }

    post({ url, data, config, errorConfig, onError }: IPostParams) {
        return this.sendRequest('post', {
            config,
            url,
            data,
            errorConfig,
            onError,
        });
    }

    put({ url, data, config, errorConfig, onError }: IPutParams) {
        return this.sendRequest('put', {
            config,
            url,
            data,
            errorConfig,
            onError,
        });
    }

    patch({ url, data, config, errorConfig, onError }: IPatchParams) {
        return this.sendRequest('patch', {
            config,
            url,
            data,
            errorConfig,
            onError,
        });
    }

    download({
        url,
        config: baseConfig = {},
        errorConfig,
        fileName,
        onError,
    }: IDownloadParams) {
        const config = {
            ...baseConfig,
            responseType: 'blob',
        };

        return this.sendRequest('get', {
            config,
            url,
            errorConfig,
            onError,
        }).then((response) => {
            const type = response.headers['content-type'];
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type }),
            );
            const link = document.createElement('a');
            const contentDisposition = response.headers['content-disposition'];

            link.href = url;
            if (
                !fileName &&
                contentDisposition &&
                contentDisposition.includes('attachment')
            ) {
                link.setAttribute(
                    'download',
                    this.getFileNameFromContentDisposition(contentDisposition),
                );
            } else if (fileName) {
                link.setAttribute('download', fileName);
            }
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();

            return response;
        });
    }
}
