import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { excludePropForward } from 'utils/styles';

const disabledStyles = ({ theme }) => css`
    color: ${theme.palette.common.black};
    cursor: auto;

    &:hover {
        text-decoration: none;
    }
`;

export const BreadcrumbsStyled = styled('div')`
    display: flex;
    align-items: center;
    font-size: 16px;
`;
BreadcrumbsStyled.displayName = 'Breadcrumbs';

export const StageNameStyled = styled(
    'a',
    excludePropForward(['disabled', 'data-test']),
)<{ disabled: boolean; 'data-test': string }>`
    user-select: none;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
        text-decoration: underline;
    }

    ${({ disabled }) => disabled && disabledStyles};
`;
StageNameStyled.displayName = 'StageName';

export const SeparatorStyled = styled('span', excludePropForward('data-test'))`
    user-select: none;
    margin: 0 5px 0 10px;
`;
SeparatorStyled.displayName = 'Separator';
