import get from 'lodash/get';

const SIMP_URL = get(window, 'env.SIMP_API_URL', '');
const SIMP_DECO_URL = get(window, 'env.SIMP_DECO_API_URL', '');

const CHANNEL = {
    GET_CHANNELS: `${SIMP_URL}/api/channels`,
    DECO_GET_CHANNELS: `${SIMP_DECO_URL}/api/channels`,
    GET_CHANNEL: `${SIMP_URL}/api/channels/{id}`,
    DECO_GET_CHANNEL: `${SIMP_DECO_URL}/api/channels/{id}`,
} as const;

const CUSTOMER_REQUEST = {
    GET_CUSTOMER_REQUEST_SUMMARY: `${SIMP_URL}/api/customer_requests/{processInstanceId}/summary`,
} as const;

const DICTIONARY = {
    GET_DICTIONARY: `${SIMP_URL}/api/dictionaries/{code}`,
    DECO_GET_DICTIONARY: `${SIMP_DECO_URL}/api/dictionaries/{code}`,
} as const;

const ORDER_INVOICE = {
    GET_DOWNLOAD_INVOICE: `${SIMP_URL}/api/order_invoices/{id}/download`,
    DECO_GET_DOWNLOAD_INVOICE: `${SIMP_DECO_URL}/api/order_invoices/{id}/download`,
    GET_ORDER_INVOICES: `${SIMP_URL}/api/orders/{id}/invoices`,
    DECO_GET_ORDER_INVOICES: `${SIMP_DECO_URL}/api/orders/{id}/invoices`,
} as const;

const ORDER_LINE = {
    GET_ORDER_LINE_NOTES: `${SIMP_URL}/api/order_lines/{id}/notes`,
    DECO_GET_ORDER_LINE_NOTES: `${SIMP_DECO_URL}/api/order_lines/{id}/notes`,
    POST_ORDER_LINE_NOTE: `${SIMP_URL}/api/order_lines/{id}/notes`,
    DECO_POST_ORDER_LINE_NOTE: `${SIMP_DECO_URL}/api/order_lines/{id}/notes`,
    PATCH_ORDER_LINE_REQUEST_HOLD_SHIPPING: `${SIMP_URL}/api/order_lines/{id}/request_hold_shipping`,
    DECO_PATCH_ORDER_LINE_REQUEST_HOLD_SHIPPING: `${SIMP_DECO_URL}/api/order_lines/{id}/request_hold_shipping`,
    GET_ORDER_ORDER_LINES: `${SIMP_URL}/api/orders/{id}/order_lines`,
    DECO_GET_ORDER_ORDER_LINES: `${SIMP_DECO_URL}/api/orders/{id}/order_lines`,
} as const;

const ORDER = {
    GET_ORDER_BALANCE: `${SIMP_URL}/api/orders/{id}/balance`,
    DECO_GET_ORDER_BALANCE: `${SIMP_DECO_URL}/api/orders/{id}/balance`,
    POST_ORDER_NOTE: `${SIMP_URL}/api/orders/{id}/notes`,
    DECO_POST_ORDER_NOTE: `${SIMP_DECO_URL}/api/orders/{id}/notes`,
    PATCH_REQUEST_ORDER_CANCELLATION: `${SIMP_URL}/api/orders/{id}/request_cancelation`,
    DECO_PATCH_REQUEST_ORDER_CANCELLATION: `${SIMP_DECO_URL}/api/orders/{id}/request_cancelation`,
    PATCH_ORDER_USE_ARTE_OFFER: `${SIMP_URL}/api/orders/{id}/use_arte_offer`,
    DECO_PATCH_ORDER_USE_ARTE_OFFER: `${SIMP_DECO_URL}/api/orders/{id}/use_arte_offer`,
    GET_ORDERS: `${SIMP_URL}/api/v2/orders`,
    POST_IMPORT_ORDERS: `${SIMP_URL}/api/v2/orders/import`,
    DECO_POST_IMPORT_ORDERS: `${SIMP_DECO_URL}/api/v2/orders/import`,
    GET_ORDER: `${SIMP_URL}/api/v2/orders/{id}`,
    DECO_GET_ORDER: `${SIMP_DECO_URL}/api/v2/orders/{id}`,
    PUT_ORDER_BILLING_ADDRESS: `${SIMP_URL}/api/v2/orders/{id}/billing_address`,
    DECO_PUT_ORDER_BILLING_ADDRESS: `${SIMP_DECO_URL}/api/v2/orders/{id}/billing_address`,
    PUT_ORDER_SHIPPING_ADDRESS: `${SIMP_URL}/api/v2/orders/{id}/shipping_address`,
    DECO_PUT_ORDER_SHIPPING_ADDRESS: `${SIMP_DECO_URL}/api/v2/orders/{id}/shipping_address`,
    PATCH_VALIDATION_COMPLETED: `${SIMP_URL}/api/orders/{id}/validation_completed`,
    DECO_PATCH_VALIDATION_COMPLETED: `${SIMP_DECO_URL}/api/orders/{id}/validation_completed`,
    GET_ORDER_TRANSACTIONS: `${SIMP_URL}/api/orders/{id}/transactions`,
    DECO_GET_ORDER_TRANSACTIONS: `${SIMP_DECO_URL}/api/orders/{id}/transactions`,
    POST_ORDERS_SEARCH: `${SIMP_URL}/api/v2/orders/search`,
    DECO_POST_ORDERS_SEARCH: `${SIMP_DECO_URL}/api/v2/orders/search`,
} as const;

const PACKAGE = {
    GET_PACKAGES_BY_ORDER_ID: `${SIMP_URL}/api/packages?orderLines.order.id={id}`,
    DECO_GET_PACKAGES_BY_ORDER_ID: `${SIMP_DECO_URL}/api/packages?orderLines.order.id={id}`,
} as const;

const PAYMENT_METHOD_DTO = {
    GET_PAYMENT_METHODS: `${SIMP_URL}/api/payment-methods`,
} as const;

export const URLS = {
    ...CHANNEL,
    ...CUSTOMER_REQUEST,
    ...DICTIONARY,
    ...ORDER_INVOICE,
    ...ORDER_LINE,
    ...ORDER,
    ...PACKAGE,
    ...PAYMENT_METHOD_DTO,
} as const;

export const DEFAULTS = {
    CHANNEL_PER_PAGE: 20,
    ORDERS_PER_PAGE: 20,
    PAYMENT_METHODS_PER_PAGE: 20,
} as const;
