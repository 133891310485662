import moment from 'moment';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';

import { FILTER_FIELD_TYPES, FILTER_MATCHER_TYPES } from 'services/constants';
import type { RouterService } from 'services/router/RouterService';

import type { TDefaultFilterKey, TDefaultFilters } from 'utils/types/filters';

/**
 * @description Function that prepares filter and sorting parameters for search service based endpoints.
 *
 * @param {object} filters
 * @param {object} sort
 * @param {object} config
 *
 * Available config properties:
 * @property {string} name
 * @property {FILTER_DEFAULT_VALUES} value - default value for the filter field
 * @property {label} label - main label value
 * @property {labelAlt} [string] - optional label value
 * @property {boolean} isModalFilter
 * @property {JSX.Element} icon
 * @property {FILTER_FIELD_TYPES} fieldType
 * @property {FILTER_MATCHER_TYPES} [matcher] - operator passed to backend to specify the type of search, e.g. exact, partial, in, etc.
 * @property {string} invertedSwitchKey - special case for switch component when we need to pass the inverted value on switch toggled on
 */
export const parsePostFilters = ({ filters, sort, config }) => {
    return {
        filter: Object.entries(filters).reduce((acc, [key, value]) => {
            const {
                matcher,
                fieldType,
                value: defaultValue,
                name: filterName,
                invertedSwitchKey,
            } = config[key];

            if (!invertedSwitchKey && isEqual(defaultValue, value)) {
                return acc;
            }

            if (fieldType === FILTER_FIELD_TYPES.SWITCH_FIELD) {
                if (invertedSwitchKey) {
                    return {
                        ...acc,
                        [invertedSwitchKey]: { [matcher]: !value },
                    };
                }

                return {
                    ...acc,
                    [key]: { [matcher]: Boolean(value) },
                };
            }

            if (
                fieldType === FILTER_FIELD_TYPES.AUTOCOMPLETE_FIELD &&
                matcher === FILTER_MATCHER_TYPES.IN_VALUE_ARRAY
            ) {
                return {
                    ...acc,
                    [key]: { [matcher]: [value] },
                };
            }

            if (matcher === FILTER_MATCHER_TYPES.DATE_RANGE) {
                return {
                    ...acc,
                    [filterName]: {
                        after: key.includes('After')
                            ? value
                            : acc[filterName]?.after,
                        before: key.includes('Before')
                            ? value
                            : acc[filterName]?.before,
                    },
                };
            }

            return {
                ...acc,
                [key]: { [matcher]: value },
            };
        }, {}),
        sort: Object.entries(sort).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [key]: toUpper(value),
            };
        }, {}),
    };
};

export const parseValueToOperator = (value, operator, filterType) => {
    if (!value) return;
    switch (operator) {
        case '$gte':
            return parseInt(value);
        case '$gt':
            return parseInt(value);
        case '$lte':
            return parseInt(value);
        case '$lt':
            return parseInt(value);
        case '$eq':
            return filterType && filterType === 'boolean'
                ? Boolean(value)
                : String(value);
        case '$match':
            return String(value);
        default:
            return value;
    }
};

export const mapFiltersParamsTypes = (filters = {}, settings = {}) => {
    const mappedFilters = {};

    Object.keys(filters).forEach((key) => {
        const filter = filters[key];
        const filterType = get(settings, `${key}.type`);

        if (key === '$or') {
            mappedFilters['$or'] = [];
            filter.forEach((item) => {
                const value = Object.values(item)[0];
                const key = Object.keys(item)[0];

                mappedFilters['$or'].push({
                    [`${key}`]: {
                        [`${Object.keys(value)[0]}`]: Boolean(
                            Object.values(value)[0],
                        ),
                    },
                });
            });
        } else if (key === '$and') {
            mappedFilters['$and'] = [];
            filter.forEach((item) => {
                const value = Object.values(item)[0];
                const key = Object.keys(item)[0];

                mappedFilters['$and'].push({
                    [`${key}`]: {
                        [`${Object.keys(value)[0]}`]: Boolean(
                            Object.values(value)[0],
                        ),
                    },
                });
            });
        } else {
            Object.keys(filter).forEach((operator) => {
                const value = filter[operator];

                set(
                    mappedFilters,
                    `${key}.${operator}`,
                    parseValueToOperator(value, operator, filterType),
                );
            });
        }
    });

    return mappedFilters;
};

export const mapFiltersToParams = (filters = {}, settings = {}) => {
    const filtersQuery = {};

    Object.keys(filters).forEach((key) => {
        const filter = filters[key];
        const filterValues = Array.isArray(filter) ? filter : [filter];
        const filterOperators = get(settings, `${key}.operators`);
        const filterType = get(settings, `${key}.type`);

        if (filterOperators) {
            filterOperators.forEach((operator, index) => {
                const filterValue = filterValues[index];

                if (filterValue) {
                    set(
                        filtersQuery,
                        `${key}.${operator}`,
                        parseValueToOperator(filterValue, operator, filterType),
                    );
                }
            });
        }
    });

    return filtersQuery;
};

export const mapParamsToFilters = (filterParams = {}, settings) => {
    const filters = {};

    const isOperatorEmpty = ({ key, filter }) => {
        if (
            settings[key]['operators'] &&
            settings[key]['operators'][0] === ''
        ) {
            set(filters, key, filter[0]);

            return true;
        }

        return false;
    };

    Object.keys(filterParams).forEach((key) => {
        let filter = filterParams[key] || {};
        const filterType = get(settings, `${key}.type`);

        if (settings[key]) {
            let filterOperators = [];
            let filterKey = key;

            if (isOperatorEmpty({ key, filter })) {
                return;
            }

            if (
                settings[key]['operators'] &&
                settings[key]['operators'][0] !== ''
            ) {
                filterOperators = get(settings, `${key}.operators`, []);
            } else if (!settings[key]['operators']) {
                for (const setting in settings[key]) {
                    filterOperators = settings[key][setting].operators || [];
                    filterKey = `${key}.${setting}`;
                    filter = filterParams[key][setting];
                }
            }

            const values = filterOperators.map((operator) => {
                const value = filter[operator];

                return parseValueToOperator(value, operator, filterType);
            });

            if (filterOperators.length > 1) {
                filters[filterKey] = values;
            } else {
                filters[filterKey] = values[0];
            }
        }
    });

    return filters;
};

export const mapRangeValueToParams = (value) => {
    if (!value) return;

    return [value.from, value.to];
};

export const mapPriceRangeValueToParams = (value) => {
    const parsedValue = {};

    if (!value) return;
    if (value.from) parsedValue.from = value.from * 100;
    if (value.to) parsedValue.to = value.to * 100;

    return mapRangeValueToParams(parsedValue);
};

export const mapDateRangeValueToParams = (value) => {
    const parsedValue = {};

    if (!value) return;
    if (value.from)
        parsedValue.from = parseInt(value.from.startOf('day').format('X'));
    if (value.to) parsedValue.to = parseInt(value.to.endOf('day').format('X'));

    return mapRangeValueToParams(parsedValue);
};

export const mapParamsToRangeValue = (value) => {
    if (!value) return;
    const [from, to] = value;

    return { from, to };
};

export const mapParamsToDateRangeValue = (value) => {
    if (!value) return;
    const parsedValues = value.map((item) =>
        item ? moment.unix(item) : undefined,
    );

    return mapParamsToRangeValue(parsedValues);
};

export const mapParamsToPriceRangeValue = (value) => {
    if (!value) return;
    const parsedValues = value.map((item) => (item ? item / 100 : undefined));

    return mapParamsToRangeValue(parsedValues);
};

export const mapFiltersToApi = (filters) => {
    const filterParams = {};

    Object.keys(filters).forEach((filterName) => {
        Object.keys(filters[filterName]).forEach((matchType) => {
            const matchTypeName = matchType ? matchType.replace('$', '') : null;
            const matchTypeFilterKey = `[${matchTypeName}]`;
            const filterKey = `filter[${filterName}]${
                matchTypeName !== 'empty' ? matchTypeFilterKey : ''
            }`;
            const value = get(filters, `${filterName}.${matchType}`);

            if (filterName === 'created_at') {
                filterParams[filterKey] = moment.unix(value).format();

                return;
            }
            if (filterName === 'width' || filterName === 'height') {
                filterParams[filterKey] = value * 10;

                return;
            }
            filterParams[filterKey] = value;
        });
    });

    return filterParams;
};

export const mapCamundaFilter = ({ name, type, typeValue, value }) => {
    let filterName, filterValue;

    switch (type) {
        case 'normal':
            filterName = name;
            filterValue = value;
            break;
        case 'boolean':
            filterName = value;
            filterValue = typeValue;
            break;
        case 'variable':
        case 'processVariable':
            filterName = `${type}s[${name}][${typeValue}]`;
            filterValue = typeValue !== 'like' ? value : `%${value}%`;
            break;
    }

    return {
        filterName: filterName,
        filterValue: filterValue,
    };
};

export const areFiltersEmpty = <
    TFilterKey extends TDefaultFilterKey = TDefaultFilterKey,
    TFilters extends TDefaultFilters<TFilterKey> = TDefaultFilters<TFilterKey>,
>(
    defaultFilters: Partial<TFilters>,
    currentFilters: Partial<TFilters>,
) =>
    !Object.keys(defaultFilters).some(
        (filterKey) =>
            !isEqual(defaultFilters[filterKey], currentFilters[filterKey]),
    );

export const filterOutEmptyFilters = <
    TFilterKey extends TDefaultFilterKey = TDefaultFilterKey,
    TFilters extends TDefaultFilters<TFilterKey> = TDefaultFilters<TFilterKey>,
>(
    defaultFilters: Partial<TFilters>,
    currentFilters: Partial<TFilters>,
) => {
    return (Object.keys(currentFilters) as TFilterKey[]).reduce<
        Partial<TFilters>
    >((prev, curr) => {
        if (!isEqual(defaultFilters[curr], currentFilters[curr])) {
            return {
                ...prev,
                [curr]: currentFilters[curr],
            };
        }

        return {
            ...prev,
        };
    }, {});
};

export const getFiltersFromSearch = (router: RouterService) => {
    const { getSearch } = router;
    const search = getSearch();

    if (search.filters && Object.keys(search.filters).length > 0) {
        return search.filters;
    }

    return {};
};

export const matchContainedFilters = <
    TFilterKey extends TDefaultFilterKey = TDefaultFilterKey,
    TFilters extends TDefaultFilters<TFilterKey> = TDefaultFilters<TFilterKey>,
>(
    defaultFilters: Partial<TFilters>,
    filters: Partial<TFilters>,
) => {
    let containedFilters: Partial<TFilters> = Object.create(null);

    for (const key in defaultFilters) {
        if (filters[key]) {
            containedFilters = {
                ...containedFilters,
                [key]: filters[key],
            };
        }
    }

    return containedFilters;
};

export const buildMappedFilters = (filters, config) => {
    return Object.keys(filters).reduce((prev, curr) => {
        const mappedFilter = config[curr];

        if (mappedFilter && filters[curr]) {
            const { filterName, filterValue } = mapCamundaFilter({
                name: mappedFilter.name,
                type: mappedFilter.type,
                typeValue: mappedFilter.typeValue,
                value: mappedFilter.customValue ?? filters[curr],
            });

            return {
                ...prev,
                [filterName]: filterValue,
            };
        } else {
            return {
                ...prev,
            };
        }
    }, {});
};

/*
    Map sort array to object with camelCase keys
 */
export const mapSortArrayToObj = (sortArray) => {
    if (Array.isArray(sortArray)) {
        return sortArray.reduce((prevVal, item) => {
            return {
                ...prevVal,
                [camelCase(item.field)]: item.sort,
            };
        }, {});
    }

    return {};
};

/**
 * Omit empty or negative values
 */
export const omitEmptyOrNegative = (obj) => {
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
        return Object.entries(obj).reduce((prevVal, [key, value]) => {
            if (Array.isArray(value) && value.length === 0) {
                return prevVal;
            }

            if (value) {
                return {
                    ...prevVal,
                    [key]: value,
                };
            }

            return prevVal;
        }, {});
    }

    return obj;
};

export const deppOmitEmpty = (obj: object) =>
    Object.fromEntries(
        Object.entries(obj).filter(
            ([, value]) =>
                value !== undefined &&
                Object.values(value).some((v) => v !== undefined && v !== null),
        ),
    );

export const valuePicker = (options, value) =>
    options.filter(({ value: itemValue }) =>
        Array.isArray(value) ? value.includes(itemValue) : value === itemValue,
    );

export const dictionaryFilterText = (options, value = '') =>
    valuePicker(options, value)
        .map(({ label }) => label)
        .join(', ');

export const dictionarySingleFilterText = (options, value = '') =>
    valuePicker(options, value)[0]?.label;
