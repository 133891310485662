import React, { useState, useEffect } from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
    ArrowIcon as ArrowForwardIosOutlinedIcon,
    ArrowLeftIcon as ArrowBackIosIcon,
} from 'components/Icons/Icons';
import { Loader } from '../Loader/Loader';
import {
    ArrowBackwardStyled,
    ArrowForwardStyled,
    CarouselWrapperStyled,
    ImageStyled,
    ImageTitleStyled,
    LoaderWrapperStyled,
    SlideWrapperStyled,
} from 'components/Carousel/carousel.styles';

import type { CarouselProps } from './carousel.type';

const imageQuery = '?size=500x500';

export const Carousel = ({ id, items }: CarouselProps) => {
    const [title, setTitle] = useState<string>('');

    const getSelectedIndex = (): number => {
        return items.findIndex((item) => item.id === id);
    };

    const getSelectedItemTitle = (index?: number): void => {
        const initialItem = items.find((item) => item.id === id);
        const title =
            index !== undefined ? items[index].name : initialItem?.name || '';

        setTitle(title);
    };

    useEffect(() => {
        getSelectedItemTitle();
    }, []);

    if (!items.length) {
        return (
            <LoaderWrapperStyled>
                <Loader aria-label="Loading" />
            </LoaderWrapperStyled>
        );
    }

    return (
        <CarouselWrapperStyled>
            <ImageTitleStyled>{title}</ImageTitleStyled>
            <ReactCarousel
                infiniteLoop={true}
                useKeyboardArrows={true}
                dynamicHeight={true}
                showStatus={false}
                selectedItem={getSelectedIndex()}
                onChange={getSelectedItemTitle}
                showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <ArrowBackwardStyled
                            onClick={onClickHandler}
                            size="large"
                        >
                            <ArrowBackIosIcon />
                        </ArrowBackwardStyled>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <ArrowForwardStyled
                            onClick={onClickHandler}
                            size="large"
                        >
                            <ArrowForwardIosOutlinedIcon />
                        </ArrowForwardStyled>
                    )
                }
            >
                {items.map((item) => (
                    <SlideWrapperStyled key={item.id}>
                        <ImageStyled
                            src={`${item.src}${imageQuery}`}
                            alt={item.name}
                        />
                    </SlideWrapperStyled>
                ))}
            </ReactCarousel>
        </CarouselWrapperStyled>
    );
};

export default Carousel;
