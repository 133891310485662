import React from 'react';
import { ClearIcon } from 'components/Icons/ClearIcon';
import { getContrastTextColor } from 'utils/colors';
import { BadgeStyled, RemoveButtonStyled } from 'components/Badge/badge.styles';
import type { BadgeProps } from './badge.types';

export class Badge extends React.PureComponent<BadgeProps> {
    constructor(props) {
        super(props);
    }

    static TYPE = {
        PRIMARY: 'primary',
        SUCCESS: 'success',
        INFO: 'info',
        WARNING: 'warning',
        DANGER: 'danger',
        DEFAULT: 'default',
    } as const;

    static COLORS = {
        BLACK: 'black',
        BLUE: 'blue',
        GREEN: 'green',
        YELLOW: 'yellow',
        RED: 'red',
    } as const;

    static SIZES = {
        LARGE: 'large',
        SMALL: 'small',
        MINI: 'mini',
    } as const;

    handleOnClick = () => {
        if (!this.props.disabled) {
            this.props.onClick?.();
        }
    };

    handleOnRemove = () => {
        if (!this.props.disabled) {
            this.props.onRemove?.();
        }
    };

    private renderRemoveButton = () => {
        let iconSize: number;

        switch (this.props.size) {
            case Badge.SIZES.MINI:
                iconSize = 8;
                break;
            case Badge.SIZES.SMALL:
                iconSize = 12;
                break;
            case Badge.SIZES.LARGE:
                iconSize = 20;
                break;
            default:
                iconSize = 16;
                break;
        }

        return (
            <RemoveButtonStyled>
                <ClearIcon
                    onClick={this.handleOnRemove}
                    name="exitCross"
                    size={iconSize}
                    aria-label="Remove Badge"
                />
            </RemoveButtonStyled>
        );
    };

    render() {
        const { children, color, type, size, customColor, disabled, onRemove } =
            this.props;

        const colorStyles = customColor
            ? {
                  backgroundColor: customColor,
                  color: getContrastTextColor(customColor),
              }
            : undefined;

        return (
            <BadgeStyled
                onClick={this.handleOnClick}
                style={colorStyles}
                disabled={disabled}
                clickable={!!this.props.onClick}
                size={size}
                color={color || type}
            >
                {onRemove && this.renderRemoveButton()}
                {children}
            </BadgeStyled>
        );
    }
}

export default Badge;
