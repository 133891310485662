import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';

import { ShopIcon } from 'components/Icons/Icons';

const OrdersTablePage = lazy(() =>
    import('../pages/OrdersTablePage').then((m) => ({
        default: m.OrdersTablePage,
    })),
);
const OrderDetailsPage = lazy(() =>
    import('../pages/OrderDetailsPage').then((m) => ({
        default: m.OrderDetailsPage,
    })),
);

export const salesRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            name: 'orders',
            path: PATHS.ORDERS,
            component: OrdersTablePage,
            exact: true,
        },
        {
            name: null,
            path: PATHS.ORDER_DETAILS,
            component: OrderDetailsPage,
            exact: true,
        },
    ],
    icon: ShopIcon,
};
