import get from 'lodash/get';

const SALES_CHANNELS_URL = get(window, 'env.SALES_CHANNELS', '');
const MEDIA_FILES_URL = get(window, 'env.MEDIA_FILES_URL', '');

const CATEGORY_DEFINITION = {
    PUT_CATEGORY_DEFINITION_OFFER_INTERMEDIATE_PRODUCTS: `${SALES_CHANNELS_URL}/api/category_definition/{id}/offer_intermediate_products`,
    GET_CHANNEL_CATEGORY_DEFINITIONS: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/category_definitions`,
    POST_CHANNEL_CATEGORY_DEFINITION: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/category_definitions`,
    GET_CHANNEL_CATEGORY_DEFINITION: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/category_definitions/{id}`,
    PATCH_CHANNEL_CATEGORY_DEFINITION: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/category_definitions/{id}`,
    DELETE_CHANNEL_CATEGORY_DEFINITION: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/category_definitions/{id}`,
} as const;

const CHANNEL_CATEGORY = {
    GET_CHANNEL_CATEGORIES: `${SALES_CHANNELS_URL}/api/channel_categories`,
} as const;

const CHANNEL_PREVIEW_TYPE = {
    GET_CHANNEL_PREVIEW_TYPES: `${SALES_CHANNELS_URL}/api/channels/{id}/preview_types`,
} as const;

const CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPES = {
    GET_CHANNEL_INTERMEDIATE_PRODUCT_WITH_PREVIEW_TYPES: `${SALES_CHANNELS_URL}/api/channels/{id}/intermediate_products_with_preview_types`,
    GET_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPE_LIST: `${SALES_CHANNELS_URL}/api/channels/{id}/intermediate_product_preview_types`,
    POST_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPES: `${SALES_CHANNELS_URL}/api/channels/{id}/intermediate_product_preview_types`,
    DELETE_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPE_ITEM: `${SALES_CHANNELS_URL}/api/channel_intermediate_product_preview_types/{id}`,
    PATCH_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPES: `${SALES_CHANNELS_URL}/api/channel_intermediate_product_preview_types/{id}`,
} as const;

const CHANNEL_TAG = {
    GET_CHANNEL_TAGS: `${SALES_CHANNELS_URL}/api/channel_tags`,
} as const;

const OFFER = {
    GET_CHANNEL_OFFER_INTERMEDIATE_PRODUCTS: `${SALES_CHANNELS_URL}/api/channels/{channelId}/offers/{id}/intermediate_products`,
    GET_OFFERS: `${SALES_CHANNELS_URL}/api/offers`,
    POST_OFFER: `${SALES_CHANNELS_URL}/api/offers`,
    GET_OFFER: `${SALES_CHANNELS_URL}/api/offers/{id}`,
    DELETE_OFFER: `${SALES_CHANNELS_URL}/api/offers/{id}`,
    PATCH_OFFER: `${SALES_CHANNELS_URL}/api/offers/{id}`,
    GET_OFFER_INTERMEDIATE_PRODUCTS: `${SALES_CHANNELS_URL}/api/offers/{id}/intermediate_products`,
    PATCH_OFFER_INTERMEDIATE_PRODUCTS: `${SALES_CHANNELS_URL}/api/offers/{id}/intermediate_products`,
    PATCH_OFFER_PRODUCTS_GENERATE_GTIN: `${SALES_CHANNELS_URL}/api/offers/{id}/offer_product/generate_gtin`,
    PATCH_OFFER_PRODUCT_SETS_GENERATE_GTIN: `${SALES_CHANNELS_URL}/api/offers/{id}/offer_product_set/generate_gtin`,
    GET_OFFER_PRICE_LISTS: `${SALES_CHANNELS_URL}/api/offers/{id}/price_lists`,
    GET_OFFER_PRODUCT_SETS: `${SALES_CHANNELS_URL}/api/offers/{id}/product_sets`,
    PATCH_OFFER_PRODUCT_SETS: `${SALES_CHANNELS_URL}/api/offers/{id}/product_sets`,
    GET_OFFER_PRODUCTS: `${SALES_CHANNELS_URL}/api/offers/{id}/products`,
    POST_VERIFY_OFFER_USING_SKU: `${SALES_CHANNELS_URL}/api/offers/{id}/verify_using_sku`,
    GET_OFFER_PRODUCTS_V2: `${SALES_CHANNELS_URL}/api/v2/offers/{id}/products`,
} as const;

const CHANNEL_PATTERN = {
    POST_IMPORT_CHANNEL_PATTERN_CATEGORIES: `${SALES_CHANNELS_URL}/api/channels/{channelId}/patterns/import_categories`,
    POST_IMPORT_CHANNEL_PATTERN_TAGS: `${SALES_CHANNELS_URL}/api/channels/{channelId}/patterns/import_tags`,
    POST_IMPORT_CHANNEL_PATTERN_TITLES: `${SALES_CHANNELS_URL}/api/channels/{channelId}/patterns/import_titles`,
    GET_CHANNEL_PATTERN_V2: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/patterns/{patternCode}`,
    PATCH_CHANNEL_PATTERN_V2: `${SALES_CHANNELS_URL}/api/v2/channels/{channelId}/patterns/{patternCode}`,
    POST_CHANNEL_PATTERN_TITLE: `${SALES_CHANNELS_URL}/api/channels/{channelId}/patterns/{patternCode}/titles`,
    PUT_CHANNEL_PATTERN_TITLE: `${SALES_CHANNELS_URL}/api/channels/{channelId}/patterns/{patternCode}/titles/{id}`,
    GET_CHANNEL_PATTERNS: `${SALES_CHANNELS_URL}/api/channels/{id}/patterns`,
    POST_CHANNEL_PATTERNS: `${SALES_CHANNELS_URL}/api/channel_patterns`,
} as const;

const CHANNEL = {
    GET_CHANNEL_CUSTOM_FIELD_DEFINITIONS: `${SALES_CHANNELS_URL}/api/channels/{id}/custom_field_definitions`,
    POST_CHANNEL_PREVIEW_TYPE: `${SALES_CHANNELS_URL}/api/channels/{id}/preview_types`,
    GET_CHANNELS: `${SALES_CHANNELS_URL}/api/v2/channels`,
    POST_CHANNEL: `${SALES_CHANNELS_URL}/api/v2/channels`,
    GET_CHANNEL: `${SALES_CHANNELS_URL}/api/v2/channels/{id}`,
    PATCH_CHANNEL: `${SALES_CHANNELS_URL}/api/v2/channels/{id}`,
    POST_CHANNEL_FEED_EXPORT: `${SALES_CHANNELS_URL}/api/v2/channels/{id}/feed/export`,
} as const;

const CUSTOM_FIELD_DEFINITION = {
    POST_CUSTOM_FIELD_DEFINITION: `${SALES_CHANNELS_URL}/api/custom_field_definitions`,
    DELETE_CUSTOM_FIELD_DEFINITION: `${SALES_CHANNELS_URL}/api/custom_field_definitions/{id}`,
    PATCH_CUSTOM_FIELD_DEFINITION: `${SALES_CHANNELS_URL}/api/custom_field_definitions/{id}`,
} as const;

const COLOR = {
    GET_COLORS: `${SALES_CHANNELS_URL}/api/colors`,
} as const;

const COMPANY = {
    GET_COMPANIES: `${SALES_CHANNELS_URL}/api/companies`,
} as const;

const CUSTOM_FIELD_VALUE = {
    POST_CUSTOM_FIELD_VALUE: `${SALES_CHANNELS_URL}/api/custom_field_values`,
    PUT_CUSTOM_FIELD_VALUE: `${SALES_CHANNELS_URL}/api/custom_field_values/{id}`,
    DELETE_CUSTOM_FIELD_VALUE: `${SALES_CHANNELS_URL}/api/custom_field_values/{id}`,
    GET_CHANNEL_CUSTOM_FIELD_VALUES: `${SALES_CHANNELS_URL}/api/v2/channels/{id}/custom_field_values`,
} as const;

const GTIN = {
    GET_SCOPE_GTINS: `${SALES_CHANNELS_URL}/api/scopes/{id}/gtins`,
    GET_GTIN_GROUPS: `${SALES_CHANNELS_URL}/api/gtin_groups`,
} as const;

const OFFER_INTERMEDIATE_PRODUCT = {
    DELETE_OFFER_INTERMEDIATE_PRODUCT: `${SALES_CHANNELS_URL}/api/offer_intermediate_products/{id}`,
} as const;

const OFFER_PRODUCT_SET = {
    POST_UPLOAD_OFFER_PRODUCT_SETS: `${SALES_CHANNELS_URL}/api/v2/offers/{id}/product_sets/upload`,
} as const;

const OFFER_PRODUCT = {
    PATCH_OFFER_PRODUCT: `${SALES_CHANNELS_URL}/api/offer_products/{id}`,
    POST_BULK_ARCHIVE_OFFER_PRODUCTS: `${SALES_CHANNELS_URL}/api/v2/offers/{id}/products/archive`,
    POST_EXPORT_OFFER_PRODUCTS: `${SALES_CHANNELS_URL}/api/v2/offers/{id}/products/export`,
    POST_UPLOAD_OFFER_PRODUCTS: `${SALES_CHANNELS_URL}/api/v2/offers/{id}/products/upload`,
} as const;

const PRICE_LIST = {
    GET_PRICE_LISTS: `${SALES_CHANNELS_URL}/api/price_lists`,
    POST_PRICE_LIST: `${SALES_CHANNELS_URL}/api/price_lists`,
    GET_EXPORT_PRICE_LIST: `${SALES_CHANNELS_URL}/api/price_lists/{id}/export`,
    GET_PRICE_LIST_INTERMEDIATE_PRODUCTS: `${SALES_CHANNELS_URL}/api/price_lists/{id}/intermediate_products`,
    PATCH_PRICE_LIST_INTERMEDIATE_PRODUCTS: `${SALES_CHANNELS_URL}/api/price_lists/{id}/intermediate_products`,
    GET_PRICE_LIST_PRODUCT_SETS: `${SALES_CHANNELS_URL}/api/price_lists/{id}/product_sets`,
    PATCH_PRICE_LIST_PRODUCT_SETS: `${SALES_CHANNELS_URL}/api/price_lists/{id}/product_sets`,
    POST_IMPORT_PRICE_LIST: `${SALES_CHANNELS_URL}/api/v2/price_lists/{id}/import`,
} as const;

const PLATFORM = {
    GET_PLATFORMS: `${SALES_CHANNELS_URL}/api/platforms`,
} as const;

const SCOPE = {
    GET_SCOPES: `${SALES_CHANNELS_URL}/api/scopes`,
    POST_SCOPE: `${SALES_CHANNELS_URL}/api/scopes`,
    GET_SCOPE: `${SALES_CHANNELS_URL}/api/scopes/{id}`,
    DELETE_SCOPE: `${SALES_CHANNELS_URL}/api/scopes/{id}`,
    PATCH_SCOPE: `${SALES_CHANNELS_URL}/api/scopes/{id}`,
} as const;

export const URLS = {
    ...CATEGORY_DEFINITION,
    ...CHANNEL_CATEGORY,
    ...CHANNEL_PREVIEW_TYPE,
    ...CHANNEL_TAG,
    ...OFFER,
    ...CHANNEL_PATTERN,
    ...CHANNEL,
    ...CUSTOM_FIELD_DEFINITION,
    ...COLOR,
    ...COMPANY,
    ...CUSTOM_FIELD_VALUE,
    ...GTIN,
    ...OFFER_INTERMEDIATE_PRODUCT,
    ...OFFER_PRODUCT_SET,
    ...OFFER_PRODUCT,
    ...PRICE_LIST,
    ...PLATFORM,
    ...SCOPE,
    ...CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPES,

    UPLOAD_CUSTOM_FILES: `${MEDIA_FILES_URL}/api/v1/custom-files`,
} as const;

export const ALERT_MESSAGES = {
    OFFER_DELETED: 'manageOffers:alerts.offerDeleted',
    OFFER_INTERMEDIATE_PRODUCTS_ADDED:
        'manageOffers:alerts.intermediateProductsAdded',
    CUSTOM_FIELD_DEFINITION_DELETED:
        'manageOffers:alerts.customFieldDefinitionDeleted',
    CUSTOM_FIELD_VALUE_DELETED: 'manageOffers:alerts.customFieldValueDeleted',
    FILE_UPLOADED_SUCCESS: 'manageOffers:alerts.fileUploadedSuccessfully',
};

export const DEFAULTS = {
    PER_PAGE: 20,
    SALES_CHANNELS_PER_PAGE: 20,
    PATTERNS_PER_PAGE: 20,
    PLATFORMS_PER_PAGE: 20,
    COMPANIES_PER_PAGE: 20,
    OFFERS_PER_PAGE: 20,
    EXPORT_MASTERFEED_POLLING_INTERVAL: 2000,
    EXPORT_OFFER_PRODUCTS_POLLING_INTERVAL: 2000,
    CATEGORY_DEFINITIONS_PER_PAGE: 20,
};

export const SALES_CHANNELS_PARAMS = {
    SORTING_KEYS: ['createdAt', 'code'],
    FILTERS_KEYS: ['search'],
};

export const COMPANIES_PARAMS = {
    SORTING_KEYS: [],
    FILTERS_KEYS: [],
};

export const OFFERS_PARAMS = {
    SORTING_KEYS: [
        'intermediate_product_count',
        'product_count',
        'updated_at',
        'created_at',
        'name',
    ],
    FILTERS_KEYS: ['name'],
};

export const OFFER_ITEMS_PARAMS = {
    SORTING_KEYS: ['createdAt', 'modifiedAt'],
    FILTERS_KEYS: ['offer', 'search', 'active'],
};

export const OFFER_PRODUCTS_PARAMS = {
    SORTING_KEYS: ['createdAt'],
    FILTERS_KEYS: [
        'product_pattern_code',
        'width',
        'height',
        'intermediate_product_id',
        'technology_id',
        'sku',
        'ean',
        'noEan',
        'productId',
    ],
};

export const OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS = {
    FILTERS_KEYS: [
        'name',
        'code',
        'technology_id',
        'components',
        'width_from',
        'width_to',
        'height_from',
        'height_to',
        'parts_count',
        'products_count_from',
        'products_count_to',
        'amount_from',
        'amount_to',
    ],
};

export const OFFER_PRODUCT_STATUSES = {
    ARCHIVED: 'archived',
    ACTIVE: 'active',
};

export const OFFER_PRODUCTS_FILTER_MAP = {
    product_pattern_code: 'product.pattern.code',
    intermediate_product_id: 'product.intermediate_product.id',
    technology_id: 'product.intermediate_product.technology.id',
    width: 'product.intermediate_product.width',
    height: 'product.intermediate_product.height',
    sku: 'sku',
    productId: 'product.id',
};
