import { trimText } from 'utils/formatting';
import { makeNote } from 'utils/note/notes';
import { IPostOrdersSearchFilters } from '../actions/order/order.type';
import { FILTER_MATCHER_TYPES } from '../../constants';
import { deppOmitEmpty } from '../../../utils/filters';

export const mapOrderFromApi = (data: any = {}) => {
    const {
        id = '',
        createdAt = '',
        orderNumber = '',
        orderName = '',
        customerName = '',
        channel = '',
        status = '',
        isBillingAddressEditable = false,
        isShippingAddressEditable = false,
        company,
    } = data;

    return {
        id,
        company,
        orderNumber,
        orderName,
        customerName,
        channel,
        status,
        createdAt,
        isBillingAddressEditable,
        isShippingAddressEditable,
    };
};

export const mapOrderBalanceFromApi = (data: any = {}) => {
    const {
        currencyCode = '',
        balanceAmount = 0,
        currencySymbol = '',
        orderDiscountAmount = 0,
        orderLinesDiscountAmount = 0,
        orderLinesTotalAmount = 0,
        orderTotalAmount = 0,
        paidAmount = 0,
        shipmentCostAmount = 0,
        totalDiscountAmount = 0,
    } = data;

    return {
        currencyCode,
        balanceAmount,
        currencySymbol,
        orderDiscountAmount,
        orderLinesDiscountAmount,
        orderLinesTotalAmount,
        orderTotalAmount,
        paidAmount,
        shipmentCostAmount,
        totalDiscountAmount,
    };
};

export const mapEditBillingAddressToApi = ({
    name,
    company,
    streetLine,
    city,
    postalCode,
    countryCode,
    provinceCode,
    taxId,
    phoneNumber,
    email,
}: any = {}) => ({
    name: trimText(name),
    company: trimText(company),
    streetLine: trimText(streetLine),
    city: trimText(city),
    postalCode: trimText(postalCode),
    countryCode: trimText(countryCode),
    provinceCode: trimText(provinceCode),
    modifiedTaxId: trimText(taxId),
    phoneNumber,
    email,
});

export const mapEditShippingAddressToApi = ({
    name,
    company,
    streetLine,
    city,
    postalCode,
    countryCode,
    phoneNumber,
    email,
    provinceCode,
}: any = {}) => ({
    name: trimText(name),
    company: trimText(company),
    streetLine: trimText(streetLine),
    city: trimText(city),
    postalCode: trimText(postalCode),
    countryCode: trimText(countryCode),
    phoneNumber: trimText(phoneNumber),
    email: trimText(email),
    provinceCode: trimText(provinceCode),
});

export const mapOrdersImportToAPI = ({
    file,
    importType,
    noteChanel,
    note,
}) => ({
    mediaFile: {
        id: `custom-files/${file}`,
    },
    channel: importType,
    note: makeNote({
        type: 'importOrder',
        channel: noteChanel || importType,
        note,
    }),
    enclosure: '"',
});

export const postOrdersSearchFilters = (
    filters: Partial<Omit<IPostOrdersSearchFilters, 'company'>>,
) =>
    deppOmitEmpty({
        // includes orderName and orderNumber
        orderName: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_EXACT]: filters.orderNumber,
        },
        customerEmail: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_EXACT]: filters.customerEmail,
        },
        customerNick: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_EXACT]: filters.customerNick,
        },
        billingAddressName: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL]:
                filters.billingAddressName,
        },
        billingAddressCompany: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL]:
                filters.billingAddressCompany,
        },
        billingAddressCity: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL]:
                filters.billingAddressCity,
        },
        billingAddressCountryCode: {
            [FILTER_MATCHER_TYPES.IN_VALUE_ARRAY]:
                filters.billingAddressCountryCode,
        },
        purchaseDate: filters.purchaseDate
            ? {
                  ...(filters.purchaseDate[0]
                      ? { after: filters.purchaseDate[0] }
                      : {}),
                  ...(filters.purchaseDate[1]
                      ? { before: filters.purchaseDate[1] }
                      : {}),
              }
            : undefined,
        'orderLines.listingId': {
            [FILTER_MATCHER_TYPES.EXACT]: filters.listingId,
        },
        channelId: {
            [FILTER_MATCHER_TYPES.IN_VALUE_ARRAY]: filters.channelId,
        },
        'order.marketplaceCountryCode': {
            [FILTER_MATCHER_TYPES.IN_VALUE_ARRAY]:
                filters.marketplaceCountryCode,
        },
        productionOrderNumber: {
            [FILTER_MATCHER_TYPES.INSENSITIVE_EXACT]:
                filters.productionOrderNumber,
        },
        'orderLine.packageTrackingNumber': {
            [FILTER_MATCHER_TYPES.INSENSITIVE_EXACT]:
                filters.packageTrackingNumber,
        },
        'order.invoices.invoiceNumber': {
            [FILTER_MATCHER_TYPES.INSENSITIVE_EXACT]: filters.invoiceNumber,
        },
        pattern: {
            [FILTER_MATCHER_TYPES.EXACT]: filters.pattern,
        },
        technology: {
            [FILTER_MATCHER_TYPES.IN_VALUE_ARRAY]: filters.technology,
        },
        status: {
            [FILTER_MATCHER_TYPES.IN_VALUE_ARRAY]: filters.status,
        },
        currentState: {
            [FILTER_MATCHER_TYPES.IN_VALUE_ARRAY]: filters.stabloStatus,
        },
        'productionOrders.statusChangeAt': filters.statusChangeAt
            ? {
                  ...(filters.statusChangeAt[0]
                      ? { after: filters.statusChangeAt[0] }
                      : {}),
                  ...(filters.statusChangeAt[1]
                      ? { before: filters.statusChangeAt[1] }
                      : {}),
              }
            : undefined,
    });
